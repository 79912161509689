// Some utility functions to allow a dropdown within a horizontal scroller to overlap its scrolling container.
// Used by [tom-select] and [field-with-dropdown]

export function calculateDropdownDimensionsWithinScroller({ surroundingScroller, dropdown, popper }) {
  popper.forceUpdate()
  if (surroundingScroller && popper.state.placement.startsWith('bottom')) {
    // Reset previous calls to calculateDropdownDimensions
    surroundingScroller.style.minHeight = 'unset'
    surroundingScroller.style.marginBottom = 'initial'
    // If we are nested within a horizontal scroller, we have to break out of its bounding box to display a dropdown
    // Popper has some heuristic when it opens the dropdown to the top.
    // In this case we can assume that it has enough space.
    const dropdownDimensions = dropdown.getBoundingClientRect()
    const scrollerDimensions = surroundingScroller.getBoundingClientRect()
    const minHeightWithDropdown = dropdownDimensions.height + dropdownDimensions.top - scrollerDimensions.top
    const originalHeight = scrollerDimensions.height
    if (minHeightWithDropdown > originalHeight) {
      const heightDifference = minHeightWithDropdown - originalHeight
      surroundingScroller.style.minHeight = `${minHeightWithDropdown}px`
      surroundingScroller.style.marginBottom = `${-1 * heightDifference}px`
    }
  }
}

export function resetScrollerAfterDropdownOverlapping(surroundingScroller) {
  if (surroundingScroller) {
    surroundingScroller.style.minHeight = ''
    surroundingScroller.style.marginBottom = ''
  }
}
