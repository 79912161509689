/*
  Render a radar chart which displays the overall environmental cost
  distribution of an LCA and its substitution attempts
 */

import OverallCostsChart from '../util/overall_costs_chart'
import asyncCompiler from '../util/async_compiler'

asyncCompiler('[overall-cost-distribution-chart]', async (element, {
  fingerprintedJSPath,
  LCACostWrappers,
}) => {
  if (fingerprintedJSPath) {
    await import(fingerprintedJSPath) // Loads window.echarts
  }
  let chart

  function init() {
    chart = window.echarts.init(element)
    element.chart = chart // expose chart instance to the DOM (and tests)
    chart.setOption(new OverallCostsChart(LCACostWrappers).options)
  }

  init()

  return [
    () => chart?.dispose,
    up.on('playground:substitution-saved', () => up.reload(element)),
    up.on('window:resized', chart.resize),
  ]
})
