/*
  Render a treemap chart which displays the detailed environmental cost
  distribution an LCA.
  Any substitutions will be highlighted with a white border.
  The user is expected to "drill down" on the displayed data by clicking on cost
  contributors and interacting with the breadcrumbs bar
 */
import DetailedCostsChart from '../util/detailed_costs_chart'
import asyncCompiler from '../util/async_compiler'
import debounce from 'lodash/debounce'

asyncCompiler('[detailed-cost-distribution-chart]', async (element, {
  fingerprintedJSPath,
  detailedLCACosts,
}) => {
  if (fingerprintedJSPath) {
    await import(fingerprintedJSPath) // Loads window.echarts
  }
  let chart
  // While hovering the chart the mouse wheel is tied to the chart zooming functionality
  // This can be unexpected/annoying when you just want to scroll _over_ the chart
  // We disable zooming for a short time while scrolling the page.
  let reEnableZoomingTimeout
  const SCROLL_DEBOUNCE_DELAY = 10 // ms
  const ENABLE_ZOOM_AFTER_SCROLLING_DELAY = 400 // ms
  const ACTIVE_PAGE_SCROLL_CLASS = '-scrolling-page'

  function init() {
    chart = window.echarts.init(element)
    element.chart = chart // expose chart instance to the DOM (and tests)
    chart.on('click', showSubstitutionModal)
    chart.setOption(new DetailedCostsChart(detailedLCACosts).options)
  }

  function showSubstitutionModal(params) {
    // Only handle clicks on leafs
    if (params.dataType === 'main' && params.data.referenceImpactId) {
      up.layer.open({
        url: `/reference_impacts/substitution_modals/${params.data.referenceImpactId}?lca_id=${params.data.lcaID}&environmental_cost_set_id=${params.data.environmentalCostSetId}&amount_unit=${params.data.amount.unit.name}&amount_value=${params.data.amount.value}`,
        layer: 'new',
        size: 'super-large',
        history: false,
        onAccepted: () => {
          up.emit('playground:substitution-requested', { referenceImpactId: params.data.referenceImpactId })
        },
      })
    }
  }

  function disableZooming() {
    element.classList.add(ACTIVE_PAGE_SCROLL_CLASS)
    clearTimeout(reEnableZoomingTimeout)
    reEnableZoomingTimeout = setTimeout(enableZooming, ENABLE_ZOOM_AFTER_SCROLLING_DELAY)
  }

  function enableZooming() {
    element.classList.remove(ACTIVE_PAGE_SCROLL_CLASS)
  }

  init()

  return [
    () => chart?.dispose,
    up.on('playground:substitution-saved', () => { up.reload('[detailed-cost-distribution-chart]') }),
    up.on('window:resized', chart.resize),
    up.on(up.viewport.get(element), 'scroll', debounce(disableZooming, SCROLL_DEBOUNCE_DELAY, { leading: true }), { passive: true }),
  ]
})
