import requireElement from '../util/require_element'

/*
 * This compiler can be utilized within a subform to present additional information rows alongside the regular rows.
 * The visibility of these rows can be controlled by a checkbox (checked -> visible, unchecked -> hidden)
 * (also see toggle_switch_helper.rb)
 *
 * Usage:
 *
 *   .subform[subform-with-info-rows]
 *     input[type="checkbox" subform-with-info-rows--toggle]
 *       .subform--row
 *         // Regular row
 *       .subform--row.-info[subform-with-info-rows--row]
 *         // Info row
 *
 */
up.compiler('[subform-with-info-rows]', (element) => {
  const INFO_ROW_TOGGLE_SELECTOR = '[subform-with-info-rows--toggle]'
  const INFO_ROW_SELECTOR = '[subform-with-info-rows--row]'
  const HIDDEN_MODIFIER = '-hidden'

  const rowToggle = requireElement(element, INFO_ROW_TOGGLE_SELECTOR)

  function init() {
    up.on(rowToggle, 'change', toggleVisibilityOfInfoRows)
  }

  function toggleVisibilityOfInfoRows() {
    Array.from(element.querySelectorAll(INFO_ROW_SELECTOR)).forEach((subformInfoRow) => {
      subformInfoRow.classList.toggle(HIDDEN_MODIFIER, !rowToggle.checked)
    })
  }

  init()
})
