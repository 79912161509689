import debounce from 'lodash/debounce'

const RESIZE_HANDLER_DELAY = 400 // ms

class TierDetector {

  constructor() {
    this._currentTier = null

    const resizeHandler = debounce(this.handleResize, RESIZE_HANDLER_DELAY).bind(this)
    up.on(window, 'resize', resizeHandler)
  }

  get currentTier() {
    if (!this._currentTier) {
      this._currentTier = this.computeCurrentTier()
    }
    return this._currentTier
  }

  get isDesktop() {
    return this.currentTier === 'desktop'
  }

  get isTablet() {
    return this.currentTier === 'tablet'
  }

  get isMobile() {
    return this.currentTier === 'mobile'
  }

  computeCurrentTier() {
    const cssHelperValue = getComputedStyle(document.querySelector('.responsive-tier-detector')).fontFamily ||
      ''
    const tier = cssHelperValue.match(/viewport-(\w+)/)?.[1]

    if (['mobile', 'tablet', 'desktop'].includes(tier)) {
      return tier
    } else {
      return undefined
    }
  }

  handleResize() {
    this.reset()
    up.emit('window:resized')
  }

  reset() {
    this._currentTier = null
  }

}

window.TierDetector = new TierDetector()
