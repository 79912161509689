// All selects in this application are using TomSelect
// The options for these selects can come from two sources:
// * Either provide all the options directly via the rendered html as usual
// * or provide a asyncOptionsPath which should return a array like [{id: 1, text: 'Some Option}]
//   where TomSelect will fetch its options, when the user toggles the dropdown.
//   The JSON endpoint should accept a `query` parameter, which filters the result.
//   A prefix which will prepended to every query can be provided via asyncOptionsFilter.

// Example usage:
//
// form.input :some_ids_attribute,
//   collection: [],
//   'tom-select': '',
//   'up-data': {
//     fingerprintedJSPath: template.path_to_asset('tom_select.js'),
//     fingerprintedCSSPath: template.path_to_asset('tom_select.css'),
//     asyncOptionsPath: Router.instance.reference_impacts_path(format: :json),
//     asyncOptionsFilter: 'unit:km',
//   }
// }

import asyncCompiler from '../util/async_compiler'
import loadStyle from '../util/load_style'
import { createPopper } from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'
import { FrontendApi } from '../util/frontend_api'
import { sameWidth } from '../../tom-select/popperjs_same_width'
import {
  calculateDropdownDimensionsWithinScroller,
  resetScrollerAfterDropdownOverlapping,
} from '../util/dropdown_in_scroller'

asyncCompiler('[tom-select]', async (element, {
  fingerprintedJSPath,
  fingerprintedCSSPath,
  asyncOptionsPath,
  asyncOptionsFilter,
  tomSelectOptions,
}) => {
  const lockStepTag = `initialize-tom-select-${Math.random()}`
  const HAS_SELECTION_MODIFIER = '-has-selection'
  const surroundingScroller = element.closest('.scroller')
  let tomSelect
  window.CapybaraLockstep?.startWork(lockStepTag)

  await Promise.all([
    import(fingerprintedJSPath), // Loads window.TomSelect
    loadStyle(fingerprintedCSSPath),
  ])

  // It may happen that the element is destroyed while the async tasks above are still running. In this case,
  // the code path would continue and create a TomSelect element, but the destructor would not be called and
  // we would create a memory leak.
  // See: https://makandracards.com/makandra/525263-destructors-for-async-unpoly-compilers
  if (!element.isConnected) {
    window.CapybaraLockstep?.stopWork(lockStepTag)
    return
  }

  function init() {
    tomSelect = new window.TomSelect(element, calculateOptions())
    // Expose the tomselect instance to the element that is visible in the DOM - useful for other compilers
    tomSelect.wrapper.tomselect = tomSelect
    registerTomSelectHandlers()
    registerUnpolyHandlers()
    updateSelected()
  }

  function calculateOptions() {
    const options = {
      onInitialize() {
        const reference = element.closest('[tom-select--dropdown-width-gauge]') || element.closest('.input-group') || element.closest('.form-group')
        this.dropdown.style.margin = '0' // needed to remove popper.js warnings due to margin in the browser console
        this.popper = createPopper(reference, this.dropdown, {
          modifiers: [flip, preventOverflow, sameWidth],
          placement: 'bottom-end',
        })
        up.hello(this.control) // activate ts_control_item.js
        up.emit(element, 'tomselect:initialized')
        up.emit(this.wrapper, 'tomselect:initialized')
        window.CapybaraLockstep?.stopWork(lockStepTag)
      },
      onChange() {
        up.hello(this.control) // activate ts_control_item.js
      },
      onItemRemove(_value, item) {
        item.tooltip?.dispose()
      },
      render: {
        item: function(data, escape) {
          return `<div tooltip-on-overflow>
                    <span class="item--title">${escape(data.text)}</span>
                    <span class="item--remove"></span>
                  </div>`
        },
      },
      ...tomSelectOptions,
      plugins: [],
    }

    if (asyncOptionsPath) {
      options.valueField = 'id'
      options.labelField = 'text'
      options.searchField = 'text'

      options.load = loadRemoteOptions
    }
    return options
  }

  function updateSelected() {
    const hasSelectedOption = tomSelect.getValue() !== ''
    element.closest('.form-group').classList.toggle(HAS_SELECTION_MODIFIER, hasSelectedOption)
  }

  const loadRemoteOptions = (query, callback) => {
    window.CapybaraLockstep?.startWork('tomselect-load')
    if (asyncOptionsFilter) {
      query = `${asyncOptionsFilter} ${query}`
    }

    FrontendApi.optionsForSelect(asyncOptionsPath, query).then((json) => {
      remoteOptionsLoaded(callback, json)
    }).catch(() => {
      console.log('Error')
      remoteOptionsLoaded(callback)
    }).finally(() => {
      calculateDropdownDimensions()
      window.CapybaraLockstep?.stopWork('tomselect-load')
    })
  }

  const remoteOptionsLoaded = (callback, json) => {
    if (up.util.isPresent(json)) {
      callback(json)
    } else {
      callback()
    }
  }

  function registerTomSelectHandlers() {
    if (asyncOptionsPath) {
      // load remote options on focus, so the dropdown gets filled when the user opens it
      tomSelect.on('focus', function() {
        tomSelect.load('')
      })
    }

    tomSelect.positionDropdown = calculateDropdownDimensions

    tomSelect.on('dropdown_close', function() {
      resetScrollerAfterDropdownOverlapping(surroundingScroller)
    })

    tomSelect.on('destroy', function() {
      this.popper.destroy()
    })

    tomSelect.on('change', updateSelected.bind(this))

    tomSelect.on('blur', function() {
      up.emit(element, 'tom-select:blur')
    })

    up.destructor(element, tomSelect.destroy.bind(tomSelect))
  }

  function calculateDropdownDimensions() {
    calculateDropdownDimensionsWithinScroller({
      surroundingScroller,
      popper: tomSelect.popper,
      dropdown: tomSelect.dropdown,
    })
  }

  function registerUnpolyHandlers() {
    const removeMultiItem = function({ id }) {
      tomSelect.removeItem(id)
    }
    up.destructor(element, up.on(tomSelect.wrapper, 'item:removed', removeMultiItem))
  }

  init()
})
