export function createReferenceImpactChooserInput(selectedReferenceImpactId, processLibraryIds, processName, onInputChange) {
  const wrapper = document.createElement('div')
  wrapper.setAttribute('reference-impact-chooser', true)

  const upData = {
    baseUnit: 'kg',
    preselectedUnits: ['ton', 'g', 'kg'],
    selectedReferenceImpactId,
    preselectedProcessLibraryIds: processLibraryIds,
  }
  wrapper.setAttribute('up-data', JSON.stringify(upData))
  wrapper.classList = 'reference-impact-chooser up-can-clean'
  wrapper.innerHTML = `
    <div class="input-group up-can-clean">
      <input class="form-control reference-impact-chooser--visible-input up-can-clean" required="true" aria-required="true" value="${processName}" reference-impact-chooser--label="true" data-bs-toggle="tooltip" type="reference_impact_chooser" aria-label="${processName}" data-bs-original-title="${processName}">
      <button class="btn btn-outline-secondary -icon-button -edit-association reference-impact-chooser--open" reference-impact-chooser--open="true" title="Open selection modal" role="button">
        <span class="icon -lci-chooser-open -x20" aria-label="Icon for “Open selection modal”"></span>
      </button>
    </div>
    <div class="form-group hidden">
      <input class="form-control hidden up-can-clean" value="${selectedReferenceImpactId}" reference-impact-chooser--value="true" label="hidden" required="true" aria-required="true" autocomplete="off" type="hidden">
    </div>
  `

  const hiddenInput = wrapper.querySelector('[reference-impact-chooser--value]')
  up.on(hiddenInput, 'change', function() {
    onInputChange(hiddenInput.value)
  })

  return wrapper
}
