import requireElement from '../util/require_element'

up.compiler('[kpi-selection-segment]', (element) => {
  const EXPANDED_MODIFIER = '-expanded'

  const kpiSelection = requireElement(element, '.kpi-selection')

  up.on('kpi:selected', function(_event, target) {
    const shouldExpand = element.contains(target)

    element.classList.toggle(EXPANDED_MODIFIER, shouldExpand)
    kpiSelection.classList.toggle(EXPANDED_MODIFIER, shouldExpand)
  })

})
