/*
 * A dynamic row is rendered within a container
 * The first thing it does is to _move itself out_.
 * This allows us to re-render the same form and target
 * only new rows within the container!
 */
up.compiler('.dynamic-row', function(row, data) {
  const markAsDeletedText = data.mark_as_deleted_text
  const unmarkAsDeletedText = data.unmark_as_deleted_text

  const DESTROY_BUTTON_SELECTOR = '.dynamic-row--destroy-button'
  const DESTROY_ICON_SELECTOR = '.dynamic-row--destroy-icon'
  const DESTROY_BUTTON_COLUMN_SELECTOR = '.subform-row-delete'
  const INPUT_AND_LABELS_SELECTOR = 'input, label, select, textarea, fieldset, .form-group, .btn'

  const destroyButton = row.querySelector(DESTROY_BUTTON_SELECTOR)
  const destroyIcon = row.querySelector(DESTROY_ICON_SELECTOR)
  const destroyColumn = row.querySelector(DESTROY_BUTTON_COLUMN_SELECTOR)
  const inputsAndLabels = row.querySelectorAll(INPUT_AND_LABELS_SELECTOR)

  let markedForDestruction = false

  const container = row.closest('.dynamic-row--container')
  if (container) {
    // Move the row out of the container so it won't be replaced
    window.CapybaraLockstep?.startWork('moving-dynamic-row-to-safety')
    up.util.task(() => {
      container.before(row)
      window.CapybaraLockstep?.stopWork('moving-dynamic-row-to-safety')
    })
  }

  const listeners = []

  if (row.classList.contains('-template-row')) {
    // If the last row is being edited the first time, a new empty row appears
    const form = row.closest('form')

    async function addNewRow(evt) {
      if (evt.target.parentElement.classList.contains('ts-control')) {
        // dont add new rows when typing within the search input of a tom select
        return
      }
      if (evt.target.parentElement.classList.contains('subform--action')) {
        // dont add new rows when a row was disabled or similar
        return
      }

      if (up.util.isBlank(evt.target.value.trim())) {
        // Our model rejects :all_blank rows, so we have to wait
        // for the first "real" input
        // Otherwise we'd get duplicate nested form IDs!
        return
      }
      window.CapybaraLockstep?.startWork(`insert-blank-row-${form.id}`)
      unbindDirtynessListener()

      await up.render({
        // if the next line throws an exception, pass the `up_id` option to `#dynamic_rows_for`
        target: up.fragment.toTarget(container),
        url: form.action,
        method: form.method,
        headers: {
          'validate-intent': 'add-new-row',
          'X-Up-Validate': ':unknown',
        },
        params: new FormData(form),
      })
      window.CapybaraLockstep?.stopWork(`insert-blank-row-${form.id}`)
    }

    const unbindDirtynessListener = up.on(row, 'input', 'input, select, textarea', addNewRow)
    listeners.push(unbindDirtynessListener)
  }

  function toggleRowDisabling(evt) {
    if (evt.type === 'keydown') {
      if (evt.code === 'Space') {
        evt.preventDefault() // prevent scrolling down on the page
      } else {
        return // ignore any other keys like tabbing or pressing shift etc.
      }
    }
    markedForDestruction = !markedForDestruction

    let iconTitle
    if (markedForDestruction) {
      iconTitle = unmarkAsDeletedText
    } else {
      iconTitle = markAsDeletedText
    }

    const disableFields = !destroyButton.classList.contains('-marker-active')

    destroyButton.classList.toggle('-marker-active', disableFields)
    destroyIcon.setAttribute('title', iconTitle)
    row.classList.toggle('-marked-for-deletion', disableFields)

    Array.from(inputsAndLabels)
      .filter(element => !destroyColumn.contains(element))
      .filter(element => element.getAttribute('type') !== 'hidden')
      .forEach(element => {
        if (!element.toggledByDynamicRow && (element.getAttribute('disabled') === 'true' || element.classList.contains('disabled'))) {
          // Do nothing if a field was already disabled before we wanted to mark it as deleted
        } else {
          element.toggledByDynamicRow = true
          element.toggleAttribute('disabled', disableFields) // disable fields
          element.classList.toggle('disabled', disableFields) // disable pointer events for addons via bootstrap
          if (disableFields) {
            element.tomselect?.disable()
          } else {
            element.tomselect?.enable()
          }
        }
      })
  }

  if (destroyButton) {
    listeners.push(up.on(destroyButton, 'click keydown', toggleRowDisabling))
  }

  return listeners
})
