import pageDirtyTracker from './page_dirty_tracker.js'

up.compiler('form', (element) => {
  const DIRTY_CLASS = '-dirty'
  let persistedState

  function init() {
    persistedState = serializedFormData()

    if (element.querySelector('.is-invalid, .invalid-feedback')) {
      onDirty()
    }
  }

  function onDirty() {
    element.classList.add(DIRTY_CLASS)
    pageDirtyTracker.trackDirty(element)
  }

  function onPristine() {
    element.classList.remove(DIRTY_CLASS)
    pageDirtyTracker.trackPristine(element)
    persistedState = serializedFormData()
  }

  function onChange(_value, _field, options) {
    if (options.origin.dataset.excludeFromDirtyChecking === 'true') {
      return
    }

    updateDirty()
  }

  function markPristine() {
    if (isMarkedDirty()) {
      up.layer.emit(element, 'form:pristine')
    }
  }

  function updateDirty() {
    if (!isActuallyDirty() && isMarkedDirty()) {
      up.layer.emit(element, 'form:pristine')
    } else if (isActuallyDirty() && !isMarkedDirty()) {
      up.layer.emit(element, 'form:dirty')
    }
  }

  function isMarkedDirty() {
    return element.classList.contains(DIRTY_CLASS)
  }

  function isActuallyDirty() {
    return persistedState !== serializedFormData()
  }

  function serializedFormData() {
    let serializedFormData = ''
    for (const [, value] of new FormData(element)) {
      if (value.constructor.name === 'File') {
        serializedFormData += value.name
        serializedFormData += value.lastModified.toString()
      } else {
        serializedFormData += value.toString()
      }
    }

    return serializedFormData
  }

  init()

  return [
    up.watch(element, onChange),
    up.on(element, 'up:fragment:inserted', (_event, fragment) => up.watch(fragment, onChange)),
    up.on(element, 'up:form:submit', markPristine),
    up.on(element, 'form:dirty', onDirty),
    up.on(element, 'form:pristine', onPristine),
    up.on(element, 'form:update-dirty', updateDirty), // This can be used to recheck the dirty status, when external changes happened.
    up.destructor(element, onPristine),
  ]
})
