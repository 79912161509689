/*
 * This compiler adds a .-focused selector to input
 * groups where a child element is currently being focused.
 */
up.compiler('.input-group', (element) => {
  const FOCUS_CLASS = '-focused'

  return [
    up.on(element, 'focusin', () => element.classList.add(FOCUS_CLASS)),
    up.on(element, 'focusout', () => {
      element.classList.remove(FOCUS_CLASS)
    }),
  ]
})
