import 'quill/dist/quill.snow.css'

import asyncCompiler from '../util/async_compiler'

asyncCompiler('textarea[quill-editor]', async (container, { placeholder, height }) => {
  const Quill = (await import('quill')).default

  const formGroup = container.closest('.form-group')
  formGroup.classList.add('-for-quill-editor')

  const content = document.createElement('div')
  content.innerHTML = container.value
  container.parentNode.insertBefore(content, container)

  if (height) {
    content.style.height = height
  }

  const quill = new Quill(content, {
    formats: ['bold', 'italic', 'underline', 'list'],
    placeholder,
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }],
      ],
    },
    theme: 'snow',
  })

  function handleTextChange() {
    container.value = quill.getText().trim() ? quill.getSemanticHTML() : ''
    const event = new Event('change', { bubbles: true })
    container.dispatchEvent(event)
  }

  quill.on('text-change', handleTextChange)

  return () => {
    quill.off('text-change', handleTextChange)
  }
})
