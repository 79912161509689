import environment from '../util/environment'
import asyncCompiler from '../util/async_compiler'
import tooltipDestructor from '../util/tooltip_destructor'
const tooltipPromise = import('bootstrap/js/dist/tooltip')

asyncCompiler('[value-mismatch-warning]', async function(element, { mismatchTooltip }) {
  const Tooltip = (await tooltipPromise).default
  const originalValue = element.getAttribute('value-mismatch-warning')
  const formGroup = element.closest('.form-group')
  formGroup.classList.add('-with-mismatch-warning')

  const warningIcon = document.createElement('span')
  warningIcon.classList.add('icon', '-lca-different-configuration-warning',
    '-x20', 'warning')
  warningIcon.setAttribute('title', mismatchTooltip)

  const label = formGroup.querySelector('.form-label')

  const tooltip = new Tooltip(warningIcon, {
    title: mismatchTooltip,
    animation: !environment.isTest,
  })

  if (environment.isTest) {
    element.tooltip = tooltip
  }

  function normalizeValue(value) {
    return (element.type === 'number') ? value.replace(',', '.') : value
  }

  function toggleWarning() {
    const comparableOriginalValue = normalizeValue(originalValue)
    const comparableElementValue = normalizeValue(element.value)

    const modified = (comparableOriginalValue !== comparableElementValue)

    if (modified) {
      tooltip.enable()
      label.appendChild(warningIcon)
    } else {
      tooltip.disable()
      warningIcon.remove()
    }
  }

  toggleWarning()

  return [
    tooltipDestructor.bind(tooltip),
    up.on(element, 'input', function() { toggleWarning() }),
    up.on(element, 'focusout', function() {
      if (element.value === '') {
        element.value = originalValue
        toggleWarning()
        // trigger the .form-group compiler to update the .-with-value modifier
        up.emit(element, 'change')
      }
    }),
  ]

})
