// This attribute can be set on links that open a new overlay with [up-layer=new]:
//
//     <a href="/records/new" up-layer="new" up-accept-event="record:saved" reload-on-accepted="#list">
//
// When the new overlay's is accepted, a fragment on the opening link's layer will be reloaded.
// In the example above, the fragment matching `#list` would be reloaded.
up.on('up:link:follow', '[reload-on-accepted]', function({ renderOptions }, link) {
  const target = link.getAttribute('reload-on-accepted') || ':main'
  renderOptions.onAccepted = () => up.reload(target)
})

// When we expand a link to a table row, that table row should also use [reload-on-accepted] when clicked.
up.macro('[up-expand] [reload-on-accepted]', function(link) {
  const row = link.closest('[up-expand]')
  row.setAttribute('reload-on-accepted', link.getAttribute('reload-on-accepted'))
})
