// Used by app/inputs/does_creatable_association.rb to open a modal to create or edit records for a select field.
up.compiler('[open-creatable-association]', (element, { paramName, associatedModelName, validationTargets, acceptEvent, modalSize }) => {
  const CAPYBARA_LOCKSTEP_TAG = 'apply-created-option'
  const selectFieldSelector = `select[name="${paramName}"]`
  const otherSelectsForSameAssociationSelector = `select[association_name="${associatedModelName}"]:not([name="${paramName}"])`

  up.on(element, 'click', onClick)

  function onClick(evt) {
    evt.preventDefault()

    up.layer.open({
      url: element.getAttribute('href'),
      layer: 'new',
      history: false,
      acceptEvent,
      onAccepted,
      ...(modalSize && { size: modalSize }),
    })
  }

  async function onAccepted(evt) {
    window.CapybaraLockstep?.startWork(CAPYBARA_LOCKSTEP_TAG)

    const otherSelectsForSameAssociation = Array.from(document.querySelectorAll(otherSelectsForSameAssociationSelector))

    // All these up.validate calls will be batched together into one request.
    const validationPromises = otherSelectsForSameAssociation.map((element) => {
      return up.validate(element, {
        headers: {
          'validate-intent': 'update-available-options',
        },
      })
    })

    validationPromises.push(up.validate(selectFieldSelector, {
      target: validationTargets,
      params: {
        [paramName]: evt.value.id,
      },
    }))

    await Promise.all(validationPromises)
    window.CapybaraLockstep?.stopWork(CAPYBARA_LOCKSTEP_TAG)

    // As we are using up.validate to update the value for the select, mark the form dirty, as no change events are fired.
    up.layer.emit(document.querySelector(selectFieldSelector), 'form:dirty')
  }
})
