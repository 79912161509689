export default function setInputValue(input, value) {
  if (input.tomselect) {
    input.tomselect.setValue(value)
  } else if (!Array.isArray(value) || (Array.isArray(value) && value.length === 1)) {
    input.value = value
  } else {
    throw new Error('You passed an array with several values, however multiple values can only be applied to TomSelect fields.')
  }

  up.emit(input, 'change') // trigger the .form-group compiler to update the .-with-value modifier
  up.emit(input, 'input') // trigger the .dynamic-row compiler to add a new row
}
