import Environment from '../util/environment'

/*
 * This compiler forces the user to actively confirm when they leave a form with
 * unsaved changes.
 */

up.compiler('form[confirm-unsaved-changes]', (element) => {
  const MODAL_PATH = '/modals/confirm_unsaved_changes'
  const CONFIRMED_ATTRIBUTE = 'discarding-confirmed'
  const MODAL_OPTIONS = {
    url: MODAL_PATH,
    history: false,
  }
  const FORM_DIRTY_CLASS = '-dirty'

  let poppedState

  function needsConfirmation() {
    return element.classList.contains(FORM_DIRTY_CLASS) && !isAlreadyConfirmed()
  }

  function markConfirmed() {
    element.setAttribute(CONFIRMED_ATTRIBUTE, 'yes')
  }

  function isAlreadyConfirmed() {
    return element.hasAttribute(CONFIRMED_ATTRIBUTE)
  }

  function isUnsavedFormInsideCurrentLayer() {
    return up.layer.current.contains(element)
  }

  function confirmNavigatingAway(evt) {
    // opening an overlay does not cause any unsaved data to be lost
    const targetOpensModal = evt.target.matches('[up-layer~="new"]')
    const targetInFormsLayer = up.layer.get(evt.renderOptions.layer) === up.layer.get(element)
    if (needsConfirmation() && !targetOpensModal && targetInFormsLayer) {
      evt.preventDefault()

      up.layer.open({
        ...MODAL_OPTIONS,
        onAccepted: () => {
          markConfirmed()
          up.navigate({ url: evt.target.href })
        },
      })
    }
  }

  function confirmDismissingLayer(evt) {
    if (needsConfirmation() && isUnsavedFormInsideCurrentLayer()) {
      evt.preventDefault()

      up.layer.open({
        ...MODAL_OPTIONS,
        onAccepted: () => {
          markConfirmed()
          up.layer.dismiss(evt.value)
        },
      })
    }
  }

  function confirmHistoryNavigation(evt) {
    if (needsConfirmation()) {
      evt.preventDefault()

      up.layer.open({
        ...MODAL_OPTIONS,
        onAccepted: () => {
          markConfirmed()
          // Preventing the up:location:restore event does not stop the current location from updating, it only stop the rendering
          // so to go back we just need to render the page again, or close the overlay
          if (isUnsavedFormInsideCurrentLayer() && !up.layer.current.isRoot()) {
            up.layer.dismiss()
          } else {
            up.navigate({ url: window.location.href, history: false })
          }
        },
        onDismissed: () => {
          // When dismissing the confirmation, restore the previous state
          window.history.pushState(poppedState, null, up.history.previousLocation)
        },
      })
    }
  }

  function confirmRefreshingPage(evt) {
    if (Environment.isDevelopment) {
      return // In development the confirmation on reload can be very annoying due the constant reloading by Guard.
    }

    if (needsConfirmation()) {
      evt.preventDefault()
      evt.returnValue = true
    }
  }

  function onPopstate(evt) {
    poppedState = evt.state
  }

  return [
    up.on('up:link:follow', confirmNavigatingAway),
    up.on('up:layer:dismiss', confirmDismissingLayer),
    up.on('up:location:restore', confirmHistoryNavigation),
    up.on(window, 'beforeunload', confirmRefreshingPage),
    up.on(window, 'popstate', onPopstate),
  ]
})
