import environment from '../util/environment.js'
import asyncCompiler from '../util/async_compiler'
import tooltipDestructor from '../util/tooltip_destructor'
const tooltipPromise = import('bootstrap/js/dist/tooltip')

asyncCompiler('[disable-button-in-pristine-forms]', async (element, { disabledTooltip, startDirty }) => {
  const Tooltip = (await tooltipPromise).default
  const formElement = element.closest('form')
  const buttonElement = element.querySelector('button')
  const tooltip = new Tooltip(element, {
    title: disabledTooltip,
    animation: !environment.isTest,
  })

  if (startDirty) {
    onFormDirty()
  } else {
    onFormPristine()
  }

  function registerHandlers() {
    return [
      up.on(formElement, 'form:pristine', onFormPristine),
      up.on(formElement, 'form:dirty', onFormDirty),
      tooltipDestructor.bind(tooltip),
    ]
  }

  function onFormDirty(evt) {
    buttonElement.removeAttribute('disabled')
    tooltip.disable()
  }

  function onFormPristine(evt) {
    buttonElement.setAttribute('disabled', '')
    tooltip.enable()
  }

  return registerHandlers()
})
