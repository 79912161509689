import asyncCompiler from '../util/async_compiler'
const tooltipPromise = import('bootstrap/js/dist/tooltip')

asyncCompiler('input.form-control[disabled]:not(.hidden), textarea.form-control[disabled]:not(.hidden), select[disabled]:not(.hidden)', async (container, data) => {
  const Tooltip = (await tooltipPromise).default
  const isSelect = container.tagName === 'SELECT'

  const formGroup = container.closest('.form-group')
  formGroup.classList.add('-with-copy')

  const copyIcon = document.createElement('span')
  copyIcon.classList.add('icon', 'copy', '-lca-duplicate', '-x20')

  formGroup.appendChild(copyIcon)

  let tooltip

  return [
    up.on(copyIcon, 'mouseenter', () => {
      tooltip = new Tooltip(copyIcon, {
        title: SelectiveI18n.t('words.copy'),
      })
      tooltip.show()
    }),

    up.on(copyIcon, 'mouseleave', () => {
      if (tooltip) {
        tooltip.dispose()
        tooltip = null
      }
    }),

    up.on(copyIcon, 'click', (e) => {
      e.stopPropagation()
      const input = formGroup.querySelector('.form-control')
      const value = isSelect ? container.options[container.selectedIndex].text : input.value
      navigator.clipboard.writeText(value)
      if (tooltip) tooltip.dispose()
      tooltip = new Tooltip(copyIcon, {
        title: SelectiveI18n.t('words.copied'),
      })
      tooltip.show()
    }),
  ]

})
