import asyncCompiler from '../util/async_compiler'
const cookiePromise = import('cookie')

asyncCompiler('[sidebar]', async function(sidebar) {
  const cookie = (await cookiePromise).default

  const INTERACTED_CLASS = '-interacted'
  const EXPANDED_CLASS = '-expanded'
  const EXPANDED_SIDEBAR_CLASS = '-for-expanded-sidebar'
  const SIDEBAR_NAVIGATION_SELECTORS = '.sidebar-menu-item, .sidebar-mini-link, .sidebar-profile--link'

  const layout = document.querySelector('.layout')
  const button = sidebar.querySelector('[sidebar-toggle-button]')

  let expanded, sidebarNavigationHandler

  function initialize() {
    // We already guess the -expanded state while rendering the DOM
    // Now we have to double check with the current tier and window sizing
    const sidebarExpanded = cookie.parse(document.cookie).sidebar_expanded || 'true'
    const expandOnLoad = TierDetector.isDesktop && sidebarExpanded === 'true'

    setCollapsibleState(expandOnLoad)

    // Hide tooltips, when the sidebar is extended
    up.on(sidebar, 'show.bs.tooltip', function(event) {
      if (expanded) {
        event.preventDefault()
      }
    })
  }

  function lockBody() {
    const { body } = document

    const scrollbarWidth = window.innerWidth - body.clientWidth
    body.style.paddingRight = `${scrollbarWidth}px`
    body.style.overflowY = 'hidden'
  }

  function unlockBody() {
    const { body } = document

    body.style.paddingRight = null
    body.style.overflowY = null
  }

  function toggleBodyLock() {
    if (!TierDetector.isMobile) {
      return
    }

    if (expanded) {
      lockBody()
    } else {
      unlockBody()
    }
  }

  function setCollapsibleState(value) {
    expanded = value
    sidebar.classList.toggle(EXPANDED_CLASS, expanded)
    layout.classList.toggle(EXPANDED_SIDEBAR_CLASS, expanded)
    toggleBodyLock()
  }

  function toggleCollapsibleState() {
    expanded = !expanded
    sidebar.classList.add(INTERACTED_CLASS)
    if (TierDetector.isDesktop) {
      document.cookie = cookie.serialize('sidebar_expanded', expanded)
    }

    setCollapsibleState(expanded)
  }

  const toggleButtonHandler = up.on(button, 'click', toggleCollapsibleState)

  // Add an event listener on mobile devices to close the sidebar as soon as a navigating item is clicked
  if (TierDetector.isMobile) {
    sidebarNavigationHandler = up.on(sidebar, 'click', SIDEBAR_NAVIGATION_SELECTORS, function() {
      setCollapsibleState(false)
    })
  }

  const resizeHandler = up.on('window:resized', initialize)

  initialize()

  return [
    resizeHandler,
    sidebarNavigationHandler,
    toggleButtonHandler,
  ]
})
