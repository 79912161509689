import environment from '../util/environment'
import './unpoly.sass'

up.layer.config.modal.dismissable = 'button key' // do not allow closing modals by clicking the backdrop

up.layer.config.overlay.dismissLabel = '' // do not show default x

up.link.config.followSelectors.push('a[href]')
up.link.config.noFollowSelectors.push('up-modal.-for-instant-modal a[href]')
up.link.config.instantSelectors.push('a[href]')
up.link.config.noInstantSelectors.push('.btn:not(.btn-link), [no-instant], [scroller] a[href]')

up.feedback.config.currentClasses.push('active')

up.form.config.submitSelectors.push('form')
up.form.config.noSubmitSelectors.push('up-modal.-for-instant-modal form')

up.motion.config.enabled = !environment.isTest

up.fragment.config.runScripts = false
up.fragment.config.autoScroll = ['hash', 'reset-if-main']

up.network.config.progressBar = true
up.history.config.updateMetaTags = true

up.layer.config.overlay.dismissLabel = ''

if (environment.isTest) {
  up.log.enable()
}
