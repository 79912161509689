import requireElement from '../util/require_element'

up.compiler('[reference-impact-chooser]', function(element, { baseUnit, preselectedUnits, selectedReferenceImpactId, preselectedProcessLibraryIds, contextIdentifier, contextTitle }) {
  const labelInput = requireElement(element, '[reference-impact-chooser--label]')
  const valueInput = requireElement(element, '[reference-impact-chooser--value]')
  // the addon icons are only visible if the chooser has a selected value
  const resetIcon = element.querySelector('[reference-impact-chooser--reset]')
  const openIcon = element.querySelector('[reference-impact-chooser--open]')

  function init() {
    return [
      up.on(labelInput, 'click', openModal),
      up.on(element, 'lci-mapping:suggestion-applied', onMappingSelected),
      // the clear icon and the button to open the modal do not exist when the reference impact field is disabled
      registerEventOnOptionalElement(openIcon, 'click', openModal),
      registerEventOnOptionalElement(resetIcon, 'click', clearSelection),

      up.watch(valueInput, function() {
        labelInput.setAttribute('data-bs-toggle', 'tooltip')
        labelInput.setAttribute('data-bs-original-title', labelInput.value)
      }),
    ]
  }

  function registerEventOnOptionalElement(element, event, callback) {
    if (element) {
      return up.on(element, event, callback)
    }
  }

  function openModal(evt) {
    evt.preventDefault() // wizard form should not be submitted when button to open the LCI Mapping modal is clicked

    const url = new URL('/reference_impacts/mapping_modals', location.href)
    if (baseUnit) {
      url.searchParams.set('base_unit', baseUnit)
    }
    if (preselectedUnits) {
      preselectedUnits.forEach((unit) => {
        url.searchParams.append('units[]', unit)
      })
    }
    if (selectedReferenceImpactId) {
      url.searchParams.set('selected_reference_impact_id', selectedReferenceImpactId)
    }
    if (preselectedProcessLibraryIds) {
      preselectedProcessLibraryIds.forEach((id) => {
        url.searchParams.append('process_library_ids[]', id)
      })
    }
    if (contextIdentifier) {
      url.searchParams.set('context_identifier', contextIdentifier)
    }
    if (contextTitle) {
      url.searchParams.set('context_title', contextTitle)
    }
    up.layer.open({
      url: url.toString(),
      mode: 'modal',
      layer: 'new',
      history: false,
      size: 'super-large',
      onAccepted: onMappingSelected,
      onDismissed: onMappingDismissed,
    })
  }

  function onMappingSelected(evt) {
    const { id, label } = evt.value
    setSelection(id, label)
    labelInput.focus()
  }

  function onMappingDismissed(evt) {
    labelInput.focus()
  }

  function clearSelection() {
    setSelection(null, null)
  }

  function setSelection(id, label) {
    labelInput.value = label
    valueInput.value = id
    selectedReferenceImpactId = id
    // triggers dynamic_row.js compiler to maybe add new rows
    up.layer.emit(labelInput, 'input')
    // triggers form_group.js compiler to add css class `-with-value`, which is important for valid styling
    up.layer.emit(labelInput, 'change')
    // trigger up.watch to set the tooltip for selected but not yet saved LCI Mapping
    up.layer.emit(valueInput, 'change')
  }

  return init()

})
