import debounce from 'lodash/debounce'
import Environment from '../util/environment'
import first from 'lodash/first'
import last from 'lodash/last'

/*
  HEADS UP: If you have a scroller that stretches across multiple rows (e.g. in a table or for wizard rows), only the
  first row within it must set the `.scroller--item` class on its items. All successive rows must **not** have it set.
  Otherwise, the jumping logic will fail because it cannot distinguish between scroller items within the row and items
  in other rows.
*/
up.compiler('[scroller]', function(element) {
  // Default should be no or the `-horizontal` class
  const isVerticalScroller = element.classList.contains('-vertical')
  const isHorizontalScroller = !isVerticalScroller
  const delta = 4 // px
  const gradientToggleDelay = 100 // ms
  const gradientVisibilityDelta = 0 // px: Adds a buffer zone to the end of a scroller where no gradient is shown
  const resizeHandlerDelay = 400 // ms
  const REMEMBER_SCROLL_POSITION = false
  const ITEM_VISIBILITY_TOLERANCE = 5 // px

  const content = element.querySelector('.scroller--content-container')
  const itemsContainer = element.querySelector('.scroller--item-container') || element
  const items = itemsContainer.querySelectorAll('.scroller--item')

  const scrollToEndButton = element.querySelector('.scroller--button.-end')

  let isScrollable = false
  let isGrabbing = false
  let scrollInitiated = false
  let dragged = false
  let grabStartPosition
  let initialScrollPosition
  let scrollStartPosition

  let lengthMethod
  let scrollLengthMethod
  let clientLengthMethod
  let offsetLengthMethod
  let scrollPositionMethod
  let offsetPositionMethod

  // The actual magic happens inside the event listeners which are registered below
  const init = function() {
    initMethodNamesForScrollerOrientation()
    ensureActiveItemVisible()
    determineScrollable()
  }

  const determineScrollable = function() {
    detectScrollableState()
    toggleGradients()
  }

  const initMethodNamesForScrollerOrientation = function() {
    if (isHorizontalScroller) {
      lengthMethod = 'width'
      scrollLengthMethod = 'scrollWidth'
      clientLengthMethod = 'clientWidth'
      offsetLengthMethod = 'offsetWidth'
      scrollPositionMethod = 'scrollLeft'
      offsetPositionMethod = 'offsetLeft'
    } else {
      lengthMethod = 'height'
      scrollLengthMethod = 'scrollHeight'
      clientLengthMethod = 'clientHeight'
      offsetLengthMethod = 'offsetHeight'
      scrollPositionMethod = 'scrollTop'
      offsetPositionMethod = 'offsetTop'
    }
  }

  const ensureActiveItemVisible = function() {
    let newPosition = 0

    const scrollOffset = up.context.scrollOffset
    if (REMEMBER_SCROLL_POSITION && scrollOffset !== undefined) {
      newPosition = scrollOffset
    } else {
      const itemsArray = Array.from(items)
      const activeItem = first(itemsArray.filter(item => item.classList.contains('active') || item.classList.contains('-active')))
      if (activeItem) {
        // We first move the center of the active item to the left-most/top-most position of the visible container.
        // Then we scroll back half of the visible container width/height to center it.
        // The scroll offset is automatically clamped between 0 and the inner width, so we do not need to worry about
        // exceeding any scroll limits.
        newPosition = activeItem[offsetPositionMethod] + (activeItem[offsetLengthMethod] / 2) - (visibleLength() / 2)
      }
    }

    content[scrollPositionMethod] = newPosition
  }

  const jumpToItem = function(item) {
    const scrollOffset = item[offsetPositionMethod] - content[scrollPositionMethod]

    if (window.CapybaraLockstep) {
      content.addEventListener('scrollend', (e) => {
        window.CapybaraLockstep?.stopWork('scrolling-scroller')
      }, { once: true })

      window.CapybaraLockstep?.startWork('scrolling-scroller')
    }

    content.scrollBy({
      left: scrollOffset,
      top: 0,
      behavior: Environment.isTest ? 'instant' : 'smooth',
    })
  }

  const jumpToCoveredEndItem = function() {
    const itemsArray = Array.from(items)

    const containerLength = visibleLength()
    let coveredItem = null

    // Determine all items that are currently (partially) visible within the scroller container.
    // Based on them, we decide which item we should jump to.
    const visibleItems = itemsArray.filter(item => {
      // Remove any sticky item from the list because they are the last visible element,
      // but not the target we wish to scroll to.
      if (!TierDetector.isMobile && item.classList.contains('-sticky')) {
        return false
      }

      // Add a visibility tolerance to compensate for decimal deviations
      const leftOfContentEnd = item[offsetPositionMethod] + ITEM_VISIBILITY_TOLERANCE <
        content[scrollPositionMethod] + containerLength
      const rightOfContentStart = content[scrollPositionMethod] < item[offsetPositionMethod] +
        item[offsetLengthMethod] - ITEM_VISIBILITY_TOLERANCE

      return leftOfContentEnd && rightOfContentStart
    })

    if (visibleItems.length > 1) {
      // Multiple items, so we scroll to the last one, if it is not fully visible to make it fully visible
      // or the one after it, otherwise.
      const lastItem = last(visibleItems)
      const lastItemCovered = lastItem[offsetPositionMethod] + lastItem[offsetLengthMethod] >
        content[scrollPositionMethod] + containerLength

      if (lastItemCovered) {
        coveredItem = lastItem
      } else {
        const lastItemIndex = itemsArray.indexOf(lastItem)
        coveredItem = itemsArray.at(lastItemIndex + 1)
      }
    } else if (visibleItems.length === 1) {
      // Only 1 item visible, that means the container is as wide as the one item or even smaller than it.
      // In this case, we can just jump to next item.
      const itemIndex = itemsArray.indexOf(first(visibleItems))
      coveredItem = itemsArray.at(itemIndex + 1)
    } else {
      console.error('No visible items found.')
    }

    if (coveredItem) {
      jumpToItem(coveredItem)
    }
  }

  const detectScrollableState = function() {
    isScrollable = content[offsetLengthMethod] < content[scrollLengthMethod]

    if (isScrollable) {
      element.classList.add('-scrollable')
    } else {
      element.classList.remove('-scrollable')
    }
  }

  const toggleGradients = function() {
    let showStartGradient = false
    let showEndGradient = false
    showStartGradient = content[scrollPositionMethod] > gradientVisibilityDelta
    showEndGradient = content[scrollPositionMethod] + content[clientLengthMethod] <
      content[scrollLengthMethod] - gradientVisibilityDelta

    element.classList.toggle('-start-gradient-visible', showStartGradient)
    element.classList.toggle('-end-gradient-visible', showEndGradient)
  }

  const startDragging = function(evt) {
    grabStartPosition = grabPosition(evt)
    isGrabbing = true
    element.classList.add('-dragging')
    initialScrollPosition = content[scrollPositionMethod]
  }

  const drag = function(evt) {
    if (!isGrabbing) {
      return
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
    // User cannot drag if they managed to lift the left mouse button (1)
    if (evt.buttons !== 1) {
      stopDragging()
      return
    }

    evt.preventDefault()

    const walk = grabPosition(evt) - grabStartPosition
    content[scrollPositionMethod] = initialScrollPosition - walk
  }

  // Helper function to determine, if the user has really dragged the content or if they only meant to click something.
  // We know a drag happens when a specific pixel amount threshold is exceeded when moving the mouse horizontally.
  const setDraggedState = function(evt) {
    const diff = Math.abs(deviceIndependentPagePosition(evt) - scrollStartPosition)
    dragged = diff >= delta
  }

  const stopDragging = function() {
    scrollInitiated = false
    isGrabbing = false
    element.classList.remove('-dragging')
  }

  // This is an event listener triggered on mouseclick.
  // If the user has dragged the content, we do not want to accidentally fire any click events.
  // This may happen, if the user starts dragging on a link and then releases the mouse for stopping.
  const preventUndesiredClick = function(evt) {
    if (dragged) {
      evt.preventDefault()
      evt.stopPropagation()
      window.removeEventListener('click', preventUndesiredClick, true)
    }
  }

  const grabPosition = function(evt) {
    const offset = content[offsetPositionMethod]

    return deviceIndependentPagePosition(evt) - offset
  }

  const deviceIndependentPagePosition = function(evt) {
    if (isHorizontalScroller) {
      return evt.pageX || evt.changedTouches[0].pageX
    } else {
      return evt.pageY || evt.changedTouches[0].pageY
    }
  }

  const visibleLength = function() {
    return content.getBoundingClientRect()[lengthMethod]
  }

  const handleMouseDown = function(evt) {
    if (!isScrollable) {
      return
    }

    if (evt) {
      scrollInitiated = true
      scrollStartPosition = deviceIndependentPagePosition(evt)

      startDragging(evt)
    }
  }

  const handleMouseUp = function(evt) {
    // Only do anything, if the user triggered mousedown inside of the horizontal scroller
    if (!scrollInitiated) {
      return
    }
    window.addEventListener('click', preventUndesiredClick, true)

    stopDragging()
    setDraggedState(evt)
  }

  const handleResize = determineScrollable // the scroller could be (un)necessary after resizing
  const resizeObserver = new ResizeObserver(debounce(handleResize, resizeHandlerDelay))
  resizeObserver.observe(element)

  const handlers = [
    up.on(element, 'touchstart', evt => handleMouseDown(evt), { passive: true }),
    up.on(element, 'mousedown', evt => handleMouseDown(evt)),
    // Register these events globally to allow further dragging when leaving the horizontal scroll container
    up.on('mousemove', drag),
    up.on('touchend', evt => handleMouseUp(evt), { passive: true }),
    up.on('mouseup', evt => handleMouseUp(evt)),

    // Prevent the built-in dragging feature in Firefox and Safari which leads to broken scroll behaviour
    up.on(element, 'dragstart', '.scroller--item', evt => evt.preventDefault()),
    up.on(content, 'scroll', debounce(toggleGradients, gradientToggleDelay), { passive: true }),

    () => {
      resizeObserver.disconnect()
    },

    up.on(content, 'up:link:follow', function(evt) {
      if (REMEMBER_SCROLL_POSITION) {
        evt.renderOptions.context = { scrollOffset: content[scrollPositionMethod] }
      }
    }),

    () => window.removeEventListener('click', preventUndesiredClick, true),
  ]

  if (scrollToEndButton) {
    handlers.push(up.on(scrollToEndButton, 'click', jumpToCoveredEndItem))
  }

  init()

  return handlers
})
