import { formatAsEuros, formatAsFixedPrecisionEuros } from './format_as_euros'

export default class OverallCostsChart {
  constructor(LCACostWrappers) {
    this.LCACostWrappers = LCACostWrappers
  }

  get options() {
    const options = {
      animation: false,
      tooltip: {
        // required to display the mouseover tooltip
      },
      legend: {
        // Each LCA / substitution is named in a legend below the chart
        textStyle: {
          color: '#ccc',
        },
        bottom: 20,
        data: this._legend,
      },
      radar: {
        indicator: this._indicator,
      },
      series: [
        {
          type: 'radar',
          lineStyle: {
            width: 3,
          },
          data: this._data,
        },
      ],
    }
    console.debug(options)
    return options
  }

  get _legend() {
    return this.LCACostWrappers.map(LCACostWrapper => LCACostWrapper.name)
  }

  get _indicator() {
    return this.LCACostWrappers[0].costs.map((benchmarkCosts, index) => {
      const allCostsForThisCategory = this.LCACostWrappers.map(LCACostPerCategory => LCACostPerCategory.costs[index].total)
      const maxCosts = Math.max(...allCostsForThisCategory)
      const minCosts = Math.min(...allCostsForThisCategory)
      let label = benchmarkCosts.name
      if (maxCosts !== minCosts) {
        label += ` (${formatAsEuros(minCosts)} – ${formatAsEuros(maxCosts)})`
      } else {
        label += ` (${formatAsEuros(minCosts)})`
      }
      return {
        name: label,
        max: maxCosts,
      }
    })
  }

  get _data() {
    return this.LCACostWrappers.map(LCACostWrapper => {
      return {
        name: LCACostWrapper.name,
        value: LCACostWrapper.costs.map(costsPerCategory => costsPerCategory.total),
        tooltip: {
          trigger: 'item',
          valueFormatter: formatAsFixedPrecisionEuros,
        },
      }
    })
  }
}
