import requireElement from '../util/require_element'
import * as Popper from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'
import { sameWidth } from '../../tom-select/popperjs_same_width'
import {
  calculateDropdownDimensionsWithinScroller,
  resetScrollerAfterDropdownOverlapping,
} from '../util/dropdown_in_scroller'

up.compiler('[field-with-dropdown]', (element, { collection }) => {
  const input = requireElement(element, 'input')
  const HIDDEN_CLASS = 'visually-hidden'
  const DROPDOWN_OPEN_MODIFIER = '-dropdown-open'
  const dropdown = requireElement(element, '.ts-dropdown')
  const optionsContainer = requireElement(dropdown, '.ts-dropdown-content')
  const surroundingScroller = element.closest('.scroller')

  let selectedOption = null
  let highlightedOption = null
  let dropdownOpen = false
  let hovering = false
  let previouslySelectedValue
  let storedValue = input.value

  const popper = Popper.createPopper(element, dropdown, {
    modifiers: [flip, preventOverflow, sameWidth],
    placement: 'bottom-end',
  })

  up.on(input, 'focusin', (evt) => {
    if (dropdownOpen) { return }

    storedValue = input.value
    openDropdown()
  })

  up.on(element, 'focusout', (evt) => {
    if (!evt.relatedTarget || !dropdown.contains(evt.relatedTarget)) {
      closeDropdown()
      emitCustomChangeEvent()
    }
  })

  up.on(input, 'input', () => {
    updateOptions()
  })

  up.on(dropdown, 'click', '.option', (evt) => {
    if (!evt.target.classList.contains('option')) { return }

    updateValue(evt.target.getAttribute('data-value'))
    closeDropdown()
  })

  // We use a mousemove here, not a mouseover, because the mousemove does not get triggered,
  // when the options change below the mouse without moving it, e.g. when the list is scrolling due to pressing the arrow key.
  up.on(dropdown, 'mouseover', '.option', (evt) => {
    if (!evt.target.classList.contains('option')) { return }
    if (hovering) { return }

    hovering = true
    updateHighlighted(evt.target)
  })

  up.on(dropdown, 'mouseout', '.option', (evt) => {
    if (!evt.target.classList.contains('option')) { return }

    hovering = false
    updateHighlighted(null)
  })

  up.on(element, 'keydown', (evt) => {
    switch (evt.key) {
      case 'ArrowUp':
        highlightPrevious()
        scrollHighlightedIntoCenter()
        evt.preventDefault()
        break

      case 'ArrowDown':
        highlightNext()
        scrollHighlightedIntoCenter()
        evt.preventDefault()
        break

      case 'Enter':
        highlightedOption?.click()
        evt.preventDefault()
        input.blur()
        break
    }
  })

  function openDropdown() {
    dropdownOpen = true
    previouslySelectedValue = input.value
    updateOptions()
    scrollSelectedIntoCenter()

    highlightedOption = selectedOption
    dropdown.classList.remove(HIDDEN_CLASS)
    element.classList.add(DROPDOWN_OPEN_MODIFIER)

    calculateDropdownDimensionsWithinScroller({ surroundingScroller, popper, dropdown })
  }

  function closeDropdown() {
    dropdownOpen = false
    highlightedOption = null
    dropdown.classList.add(HIDDEN_CLASS)
    element.classList.remove(DROPDOWN_OPEN_MODIFIER)
    resetScrollerAfterDropdownOverlapping(surroundingScroller)
  }

  function updateOptions() {
    optionsContainer.innerHTML = ''
    selectedOption = null
    filteredCollection().forEach((option) => {
      let optionInnerHTML = option
      const selected = (option === input.value)

      if (up.util.isPresent(input.value) && !selected) {
        const matchStart = option.toLowerCase().indexOf(input.value.toLowerCase())
        const matchEnd = matchStart + input.value.length

        const preMatch = option.substring(0, matchStart)
        const match = option.substring(matchStart, matchEnd)
        const postMatch = option.substring(matchEnd, option.length)

        optionInnerHTML = `${preMatch}<span class="highlight">${match}</span>${postMatch}`
      }

      const optionElement = up.element.createFromHTML(`<div data-selectable class="option" data-value="${option}" role="option">${optionInnerHTML}</div>`)
      if (selected) {
        optionElement.classList.add('selected')
        selectedOption = optionElement
      }
      optionsContainer.appendChild(optionElement)
    })

    if (selectedOption) {
      highlightedOption = selectedOption
    } else {
      if (input.value) {
        const createOption = up.element.createFromHTML(`<div data-selectable class="option" data-value="${input.value}" role="option">Add <b>${input.value}</b>...</div>`)
        optionsContainer.insertBefore(createOption, optionsContainer.firstChild)
        updateHighlighted(createOption)
      }
    }

    calculateDropdownDimensionsWithinScroller({ surroundingScroller, popper, dropdown })
  }

  function updateHighlighted(newHighlightedOption) {
    if (highlightedOption) {
      highlightedOption.classList.remove('active')
    }
    if (newHighlightedOption) {
      highlightedOption = newHighlightedOption
      newHighlightedOption.classList.add('active')
    }
  }

  function highlightNext() {
    let previousOption = highlightedOption?.nextSibling
    previousOption ??= dropdown.querySelector('.option:first-of-type')

    updateHighlighted(previousOption)
  }

  function highlightPrevious() {
    let nextOption = highlightedOption?.previousSibling
    nextOption ??= dropdown.querySelector('.option:last-of-type')

    updateHighlighted(nextOption)
  }

  function scrollHighlightedIntoCenter() {
    highlightedOption?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  function scrollSelectedIntoCenter() {
    selectedOption?.scrollIntoView({ behavior: 'instant', block: 'nearest' })
  }

  function filteredCollection() {
    const additionalItems = []
    if (previouslySelectedValue && !collection.includes(previouslySelectedValue)) {
      additionalItems.push(previouslySelectedValue)
    }

    return collection
      .filter((element) => element.toLowerCase().includes(input.value.toLowerCase()))
      .concat(additionalItems)
      .sort((a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      })
  }

  function updateValue(value) {
    input.value = value
    up.emit(input, 'input')
    up.emit(input, 'change')
    emitCustomChangeEvent()
  }

  function emitCustomChangeEvent() {
    // Emit a custom change event for when the input has finally changed its value.
    //
    // Imagine this scenario:
    //   When the input is already filled. you focus it to open the dropdown,
    //   you empty the existing value with backspace and click one of the options.
    //   Then a native change event with a value of '' fires for the input, because you changed the input´s value and blurred it.
    //   The input is filled immediately after by the clicking the option, but any other listeners that listen the change event have already fired.
    //   If one of those listeners triggers an up.validate then the value given to this up.validate is '' which might clear the field again.
    //
    // As we can´t cancel this native change event, we emit a custom change event after the dropdown is closed and the input has the correct value.
    // When listening to changes for this input consider listening to field-with-dropdown:change instead of change.

    if (storedValue !== input.value) {
      storedValue = input.value
      up.emit(input, 'field-with-dropdown:change')
    }
  }

  return () => { popper.destroy() }
})
