import '../../jquery'

import 'jquery.fancytree/dist/skin-lion/ui.fancytree.css'
import 'jquery.fancytree'
import 'jquery.fancytree/dist/modules/jquery.fancytree.dnd5'
import 'jquery.fancytree/dist/modules/jquery.fancytree.edit'
import 'jquery.fancytree/dist/modules/jquery.fancytree.table'
import { createReferenceImpactChooserInput } from './reference_impact_chooser_input'

async function request(url, body, method) {
  return fetch(url, {
    method: method || 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify(body),
  })
}

async function addNewNode(node, title, rootNodeId) {
  const response = await request('/process_mappings', {
    name: title,
    parent_id: node.parent.isRootNode() ? rootNodeId : node.parent.key,
  }, 'POST')

  return await response.json()
}

async function changeNodeName(node, newTitle, originalTitle) {
  const response = await request(`/process_mappings/${node.key}`, {
    name: newTitle,
  })

  node.setTitle(response.ok ? newTitle : originalTitle)
}

async function saveReferenceImpact(nodeId, referenceImpactId) {
  return request(`/process_mappings/${nodeId}`, {
    reference_impact_id: referenceImpactId,
  })
}

up.compiler('[fancytree]', async (element, { rootNodeId, processLibraryIds }) => {
  if (!processLibraryIds || processLibraryIds.length === 0) {
    const warning = document.createElement('div')
    warning.classList = 'alert alert-warning my-2'
    warning.textContent = SelectiveI18n.t('process_mappings.form.no_process_libraries')
    element.after(warning)
    element.remove()
    return
  }

  const addChildButton = document.createElement('button')
  addChildButton.textContent = SelectiveI18n.t('process_mappings.form.add_mapping')
  addChildButton.classList = 'btn btn-outline-secondary mx-2'
  addChildButton.onclick = async () => {
    const tree = window.$.ui.fancytree.getTree(element)
    const node = tree.getActiveNode()
    if (node) {
      tree.applyCommand('addChild', node)
    } else {
      const tree = window.$.ui.fancytree.getTree(element)
      tree.applyCommand('addChild', tree.rootNode)
    }
  }

  window.$(element).fancytree({
    extensions: ['edit', 'dnd5', 'table'],
    source: {
      url: `/process_mappings/${rootNodeId}/children`,
    },
    table: {
      indentation: 20,
      nodeColumnIdx: 0,
    },
    dnd5: {
      // https://github.com/mar10/fancytree/wiki/ExtDnd5
      dragStart: function(node, data) {
        return !node.isFolder()
      },
      dragEnter: function(node, data) {
        if (data.node.parent?.isRootNode()) {
          // makes it possible to move nodes to the root
          return ['over', 'after', 'before']
        }
        return 'over'
      },
      dragDrop: async function(node, data) {
        if (data.otherNode) {
          let parentId = node.key
          if (data.hitMode !== 'over') {
            parentId = rootNodeId
          }

          await request(`/process_mappings/${data.otherNode.key}`, {
            parent_id: parentId,
          })

          data.otherNode.moveTo(node, data.hitMode)
        }
      },
    },
    edit: {
      inputCss: { minWidth: '10em', background: 'none', color: 'white', border: 'none', outline: 'none' },
      triggerStart: ['clickActive', 'f2', 'dblclick'],
      save: async function(event, data) {
        if (data.isNew) {
          const response = await addNewNode(data.node, data.input.val(), rootNodeId)
          data.node.key = response.id
        } else {
          await changeNodeName(data.node, data.input.val(), data.orgTitle)
        }

        data.node.setFocus()
        data.node.setActive()
      },
    },
    activate: function(event, data) {
      addChildButton.disabled = !data.node
    },
    lazyLoad: function(event, data) {
      data.result = {
        url: `/process_mappings/${data.node.key}/children`,
        data: { mode: 'children', parent: data.node.key },
        cache: false,
      }
    },
    renderColumns: function(event, data) {
      const node = data.node
      const $tdList = window.$(node.tr).find('>td')

      const selectedReferenceImpactId = node.data.reference_impact_id
      const processName = node.data.reference_impact_process_name || ''
      const input = createReferenceImpactChooserInput(selectedReferenceImpactId, processLibraryIds, processName, function(value) {
        console.log(`assigned reference impact ${value} to node ${data.node.key}`)
        saveReferenceImpact(data.node.key, value)
      })

      // (index #0 is rendered by fancytree)
      $tdList.eq(1).html(input)

      up.hello(input)
    },
  })

  element.after(addChildButton)

  up.destructor(element, () => {
    window.$(element).fancytree('destroy')
  })
})
