/*
  This compiler takes a message from a 'input[confirmation-dialog]'.
  The message is then displayed in a modal which can be confirmed or canceled.
  If its confirmed, the input´s value is set to 'true' and the surrounding form gets submitted.

  See 'app/models/traits/does_confirmable.rb'
*/
up.compiler('[confirmation-dialog]', (element, { title, message, show }) => {
  const form = element.closest('form')
  title ||= SelectiveI18n.t('words.caution')

  if (show) {
    up.layer.open({
      content: dialogTemplate(message, title),
      mode: 'modal',
      layer: 'new',
      onAccepted: (_evt) => {
        element.value = 'true'
        up.submit(form)
      },
      onDismissed: () => {
        up.layer.emit(form, 'form:dirty')
      },
    })
  }

  function dialogTemplate(message, title) {
    return `
      <div class="confirmation-dialog">
        <div class="confirmation-dialog--icon"></div>
      
        <h2 class="confirmation-dialog--title">${title}</h2>

        <div class="confirmation-dialog--message">${message}</div>

        <div class="action-bar -with-action-count-2">
          <button class="btn btn-outline-secondary" up-dismiss type="button">
            ${SelectiveI18n.t('words.cancel')}
          </button>

          <button class="btn btn-secondary" up-accept type="button">
            ${SelectiveI18n.t('words.confirm')}
          </button>
        </div>
      </div>
    `
  }
})
