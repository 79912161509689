import requireElement from '../util/require_element'

up.compiler('[filter]', function(filterForm) {
  const resetButton = requireElement(filterForm, '[filter--reset]')
  const queryInput = requireElement(filterForm, '[filter--query]')

  up.on(resetButton, 'click keydown', (evt) => {
    if (evt.type === 'keydown') {
      if (evt.code === 'Space') {
        evt.preventDefault() // prevent scrolling down on the page
      } else {
        return // ignore any other keys like tabbing or pressing shift etc.
      }
    }
    queryInput.value = ''
    up.emit(queryInput, 'change')
    queryInput.focus()
  })

})
