/*
 * Opens a new tab for each possible tuple of origin X destination
 * Example Usage:
 *
 * %input(id=outside-origin)
 * %div(maps-opener data-destination-selector="#outside-origin")
 *   %select(maps-opener--destination)
 *   %a(maps-opener--link) Show routes on Google Maps
 *
 * There are several usage variants:
 * * origin and target can be single value inputs, then assumed to be geographical places
 * * if they are <select>s, they are assumed to be a list of locations
 *   the compiler will ask an API endpoint to resolve selected location to their geographical places
 * * if their elements are located outside of the [maps-opener] root node, you can pass their
 *   unique DOM selector via the data-origin-selector and data-destination-selector arguments.
 */
import requireElement from '../util/require_element'
import Environment from '../util/environment'

up.compiler('[maps-opener]', (element, options) => {
  const linkElement = requireElement(element, '[maps-opener--link]')
  const originInput = requireEither(options.originSelector, '[maps-opener--origin]')
  const destinationInput = requireEither(options.destinationSelector, '[maps-opener--destination]')

  function init() {
    up.on(linkElement, 'click', openMapsLinks)
  }

  async function openMapsLinks(evt) {
    evt.preventDefault()
    const origins = await extractLocations(originInput)
    const destinations = await extractLocations(destinationInput)
    if (up.util.isBlank(origins) || up.util.isBlank(destinations)) {
      showInsufficientDataModal()
    } else {
      openMapsTabs(origins, destinations)
    }
  }

  async function extractLocations(input) {
    let locations

    if (input.nodeName === 'SELECT') {
      locations = Array.from(input.selectedOptions).map(option => option.value)
    } else {
      locations = up.util.wrapList(input.value)
    }

    return locations.filter(location => up.util.isPresent(location))
  }

  function showInsufficientDataModal() {
    showNotice(SelectiveI18n.t('maps_opener.insufficient_data_modal_content'))
  }

  function showNotice(message) {
    up.layer.open({
      content: `
        <div>
          <h2>${SelectiveI18n.t('words.notice')}</h2>

          ${message}

          <br>

          <div class="action-bar -with-action-count-1">
            <button class="btn btn-primary" up-dismiss type="button">
              ${SelectiveI18n.t('words.close')}
            </button>
          </div>
        </div>
      `,
      mode: 'modal',
      layer: 'new',
    })
  }

  function openMapsTabs(origins, destinations) {
    const googleMapsTabUrls = origins.flatMap(origin => {
      return destinations.map(destination => {
        /* https://developers.google.com/maps/documentation/urls/get-started#directions-action */
        const mapsUrl = new URL('https://www.google.com/maps/dir/')
        mapsUrl.searchParams.append('api', '1')
        mapsUrl.searchParams.append('origin', origin)
        mapsUrl.searchParams.append('destination', destination)
        mapsUrl.searchParams.append('travelmode', 'driving')
        return mapsUrl.toString()
      })
    })

    if (Environment.isTest) {
      // Don't actually talk to Google Maps in tabs to reduce flakyness and external dependencies
      window.lastOpenedTabs = googleMapsTabUrls
    } else {
      googleMapsTabUrls.forEach(url => window.open(url))
    }
  }

  function requireEither(dynamicSelector, fallbackSelector) {
    if (dynamicSelector) {
      // The dynamic selector is usually located outside of the element itself
      return requireElement(document, dynamicSelector)
    } else {
      return requireElement(element, fallbackSelector)
    }
  }

  init()
})
