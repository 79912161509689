// Open a unpoly modal whenever a 'open-modal' event is received
// With this you can open a modal directly from a controller using:
//   up.layer.emit('open-modal', content: 'Hallo')

up.on('open-modal', (evt) => {
  up.layer.open({
    url: evt.url,
    mode: 'modal',
    history: false,
  })
})
