import environment from '../util/environment'
import asyncCompiler from '../util/async_compiler'
import tooltipDestructor from '../util/tooltip_destructor'
const tooltipPromise = import('bootstrap/js/dist/tooltip')

asyncCompiler('[checkbox-mismatch-warning]',
  async function(element, { mismatchTooltip }) {
    const Tooltip = (await tooltipPromise).default
    const originalValues = element.getAttribute(
      'checkbox-mismatch-warning').split(' ').map(v => v.trim())

    const registeredTooltips = {}

    async function updateElement(input) {
      const {
        checked,
        value,
      } = input
      const isDifferent = checked !== originalValues.includes(value)
      const wrap = input.closest('.form-check')
      const label = wrap.querySelector('.form-check-label')

      if (isDifferent) {
        if (!registeredTooltips[value]) {
          const warningIcon = document.createElement('span')
          warningIcon.classList.add('icon', '-lca-different-configuration-warning', '-x20', 'warning')
          warningIcon.setAttribute('title', mismatchTooltip)
          label.appendChild(warningIcon)
          registeredTooltips[value] = new Tooltip(warningIcon, {
            title: mismatchTooltip,
            animation: !environment.isTest,
          })
        }
      } else if (registeredTooltips[value]) {
        await tooltipDestructor.call(registeredTooltips[value])
        label.querySelector('.warning').remove()
        delete registeredTooltips[value]
      }

    }

    element.querySelectorAll('input[type="checkbox"]').forEach(updateElement)

    return [
      async () => { Promise.all(Object.values(registeredTooltips).map(tooltip => tooltipDestructor.call(tooltip))) },
      up.on(element, 'change', function(ev) { updateElement(ev.target) }),
    ]
  })
