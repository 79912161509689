export default new class Environment {

  get toString() {
    return document.body.dataset.environment
  }

  get isTest() {
    return this.toString === 'test'
  }

  get isFeatureSpec() {
    return this.isTest && !('jasmine' in window)
  }

  get isJasmineSpec() {
    return this.isTest && ('jasmine' in window)
  }

  get isDevelopment() {
    return this.toString === 'development'
  }

}()
