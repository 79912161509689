up.compiler('[kpi-tile]', (element) => {
  const EXPANDED_MODIFIER = '-expanded'
  const ACTIVE_MODIFIER = '-active'

  up.on(element, 'click', () => up.emit(element, 'kpi:selected'))

  up.on('kpi:selected', function(_event, selectedElement) {
    const haveSameParent = element.parentElement === selectedElement.parentElement

    element.classList.toggle(EXPANDED_MODIFIER, element === selectedElement || haveSameParent)
    element.classList.toggle(ACTIVE_MODIFIER, element === selectedElement)
  })

})
