/*
  Usage:
    headers = ['Names']
    rows = [['Peter'], ['Pan']]
    new CSVBuilder(headers, rows).toString()
 */

export default class CSVBuilder {
  constructor(headers, rows) {
    this.data = [headers, ...rows]
    this.DELIMITER = ','
    this.ESCAPE_CHARACTER = '"'
    this.NEWLINE = '\r\n'
  }

  toString() {
    return this.data.map((row) => {
      return this.sanitizeRow(row)
    }).join(this.NEWLINE)
  }

  sanitizeRow(rowArray) {
    return rowArray.map(cell => {
      let sanitizedCell
      if (cell === null) {
        sanitizedCell = ''
      } else {
        sanitizedCell = cell.toString()
      }
      // Escape already existing escape characters
      sanitizedCell = sanitizedCell.replace(RegExp(this.ESCAPE_CHARACTER, 'g'), this.ESCAPE_CHARACTER + this.ESCAPE_CHARACTER)

      if (sanitizedCell.search(RegExp(`${this.ESCAPE_CHARACTER}|${this.DELIMITER}|\n`, 'g')) >= 0) {
        // Wrap cells that contain special characters which might break the CSV format with quotes
        sanitizedCell = `${this.ESCAPE_CHARACTER}${sanitizedCell}${this.ESCAPE_CHARACTER}`
      }
      return sanitizedCell
    }).join(this.DELIMITER)
  }
}
