/*
 * This compiler
 * - adds a .-focused selector to form groups where a child element is currently being focused.
 * - adds a .with-value selector to form groups where the input element holds a value.
 * - auto-adjusts the position & width of the label to place it correctly within its input field (minding input groups).
 */
import debounce from 'lodash/debounce'
import MobileDetector from '../util/is_mobile'

up.compiler('.form-group', { priority: 1 }, (element) => {
  const FOCUS_CLASS = '-focused'
  const WITH_VALUE_CLASS = '-with-value'
  const LABEL_SELECTOR = '.form-label'
  const INPUT_GROUP_SELECTOR = '.input-group'

  const FORM_CONTROL_SELECTOR = '.form-control:not(.country_selection)'
  const FORM_SELECT_SELECTOR = '.form-select, .form-control.country_selection'
  const TS_CONTROL_SELECTOR = '.ts-control'
  const INPUT_SELECTORS = `${FORM_CONTROL_SELECTOR}, ${FORM_SELECT_SELECTOR}`

  const resizeHandlerDelay = 400 // ms

  if (element.classList.contains('radio_buttons')) {
    return // this compiler does not apply to radio buttons in any way
  }
  if (element.classList.contains('hidden')) {
    return // this compiler is only adjusting the visual representation of form-groups
  }

  const label = element.querySelector(LABEL_SELECTOR)
  const formControlInput = element.querySelector(FORM_CONTROL_SELECTOR)
  const selectInput = element.querySelector(FORM_SELECT_SELECTOR)
  const input = formControlInput || selectInput

  if (!input) {
    // Nothing to do for collection check boxes etc.
    return
  }

  let resizeObserver

  const toggleValueIndicator = function(value) {
    // Because `evt.target.placeholder` is always a string and contains `'undefined'` as string instead of the JS
    // `undefined` in case it is not set. JS-API ftw.
    if (value === 'undefined') {
      value = false
    }

    element.classList.toggle(WITH_VALUE_CLASS, !!value)
  }

  const fitLabelIntoInput = function() {
    // If we have a select control, the .ts-control component is the visible one and the actual select is shrunken
    const tsControlInput = element.querySelector(TS_CONTROL_SELECTOR)
    const inputForCalculations = tsControlInput || formControlInput
    if (!inputForCalculations) {
      return
    }

    const styles = window.getComputedStyle(inputForCalculations)
    const paddingLeft = parseFloat(styles.paddingLeft)
    const padding = paddingLeft + parseFloat(styles.paddingRight)
    const inputWidth = inputForCalculations.clientWidth - padding

    // .input-group is a relative offset parent, so offsetLeft is relative to it
    label.style.left = `${inputForCalculations.offsetLeft + paddingLeft}px`
    label.style.maxWidth = `${inputWidth}px`
  }

  const registerLabelHandler = function() {
    const inputGroup = input.closest(INPUT_GROUP_SELECTOR)
    // If there is no input group, there is only the input and we can rely on the default CSS positioning for the label.
    if (inputGroup) {
      resizeObserver = new ResizeObserver(debounce(fitLabelIntoInput, resizeHandlerDelay))

      const inputGroupChildren = Array.from(inputGroup.children)
      inputGroupChildren.forEach((child) => {
        resizeObserver.observe(child)
      })

      fitLabelIntoInput()
    }
  }

  const setValueIndicatorWithSearchQuery = function(evt) {
    let currentValue = evt.target.value || evt.target.placeholder
    const formSelect = evt.target.closest(FORM_SELECT_SELECTOR)
    if (formSelect) {
      // If the form group belongs to a tom select with one or more values
      // we can only consider the value blank if the search text is blank
      // AND no option was already chosen
      const searchQuery = currentValue
      const selectedValues = up.util.toArray([formSelect.tomselect.getValue()]).join()
      currentValue = selectedValues + searchQuery
    }
    toggleValueIndicator(currentValue)
  }

  // When the user closes a tom-select value, the search query is flushed and no longer relevant
  const setValueIndicatorWithoutSearchQuery = function(evt) {
    const formSelect = evt.target.closest(FORM_SELECT_SELECTOR)
    let placeholder = evt.target.placeholder

    if (formSelect && formSelect.tomselect) {
      const selectedValues = up.util.toArray([formSelect.tomselect.getValue()]).join()
      toggleValueIndicator(selectedValues || placeholder)
    } else {
      const filePickerFileName = evt.target.closest('[file-input]')?.querySelector('[file-input--filename]')?.textContent
      const isNativeDatePicker = evt.target.type === 'date' && MobileDetector.isMobile
      if (filePickerFileName && filePickerFileName !== '(No file chosen)') {
        placeholder += filePickerFileName
      }
      if (isNativeDatePicker) {
        placeholder += 'mm/dd/yyyy' /* Default placeholder for native date inputs (EN) */
      }

      toggleValueIndicator(evt.target.value || placeholder)
    }
  }

  setValueIndicatorWithoutSearchQuery({ target: input })

  if (selectInput) {
    up.on(element, 'tomselect:initialized', function() {
      registerLabelHandler()
    })
  } else {
    registerLabelHandler()
  }

  return [
    up.on(element, 'input', INPUT_SELECTORS, setValueIndicatorWithSearchQuery),
    up.on(element, 'focusin', () => element.classList.add(FOCUS_CLASS)),
    up.on(element, 'focusout', () => element.classList.remove(FOCUS_CLASS)),
    up.on(element, 'change', INPUT_SELECTORS, setValueIndicatorWithoutSearchQuery),
    () => {
      resizeObserver?.disconnect()
    },
  ]
})
