import requireElement from '../util/require_element'
import setInputValue from '../util/set_input_value'

/*
 * This compiler allows to set an input ([form-with-suggestions-field--input]) to the suggested value by
 * clicking the picker ([form-with-suggestions-field--picker]). The suggestion ([form-with-suggestions-field--suggestion])
 * is hidden if the input field already has a value or if no suggestion is available. If the value of the input field
 * gets flushed the suggestion gets shown again.
 *
 * This compiler works in combination with the compiler that triggers the validation of this element
 * (see: form_with_suggestions_trigger.js)
 *
 * Usage:
 *   [form-with-suggestions-field]
 *     input[name=bar][form-with-suggestions-field--input]
 *     [form-with-suggestions-field--suggestion]
 *       [form-with-suggestions-field--picker]
 */
up.compiler('[form-with-suggestions-field]', (element, data) => {
  const inputForSuggestion = requireEither(data.inputForSuggestionSelector, '[form-with-suggestions-field--input]')
  const suggestionContainer = requireElement(element, '[form-with-suggestions-field--suggestion]')
  const suggestionPicker = requireElement(element, '[form-with-suggestions-field--picker]')

  const HIDDEN_MODIFIER = '-hidden'

  let suggestedValues = []
  let suggestedValuesLabel = ''

  function init() {
    const parsedValues = JSON.parse(data.suggestedValues)

    if (up.util.isPresent(parsedValues)) {
      suggestedValues = parsedValues
    }

    if (up.util.isPresent(data.suggestedValuesLabel)) {
      suggestedValuesLabel = data.suggestedValuesLabel
    }

    up.on(suggestionPicker, 'click keydown', applySuggestedValues)
    up.watch(inputForSuggestion, toggleSuggestionVisibility)

    toggleSuggestionVisibility()
  }

  function applySuggestedValues(evt) {
    if (evt.type === 'keydown') {
      if (evt.code === 'Space') {
        evt.preventDefault() // prevent scrolling down on the page
      } else {
        return // ignore any other keys like tabbing or pressing shift etc.
      }
    }
    if (up.util.isBlank(suggestedValues)) return

    if (element.querySelector("[reference-impact-chooser='true']")) {
      setValueForReferenceImpactChooser()
    } else {
      setInputValue(inputForSuggestion, suggestedValues)
    }

    up.emit(inputForSuggestion, 'form-field:suggestion-applied')
  }

  function setValueForReferenceImpactChooser() {
    const referenceImpactChooser = element.querySelector("[reference-impact-chooser='true']")
    up.emit(referenceImpactChooser, 'lci-mapping:suggestion-applied', { value: { id: suggestedValues[0], label: suggestedValuesLabel } })
  }

  function toggleSuggestionVisibility() {
    const hideSuggestion = up.util.isPresent(inputForSuggestion.value) || up.util.isBlank(suggestedValues)
    suggestionContainer.classList.toggle(HIDDEN_MODIFIER, hideSuggestion)
  }

  function requireEither(dynamicSelector, fallbackSelector) {
    if (dynamicSelector) {
      return requireElement(element, dynamicSelector)
    } else {
      return requireElement(element, fallbackSelector)
    }
  }

  init()
})
