up.compiler('[units-field]', (element) => {
  return [
    up.on(element, 'focusin', 'input', () => toggleFocusStylesForAllFormGroups(true)),
    up.on(element, 'focusout', 'input', () => toggleFocusStylesForAllFormGroups(false)),
  ]

  function toggleFocusStylesForAllFormGroups(focus) {
    Array.from(element.querySelectorAll('.form-group')).forEach((formGroup) => {
      formGroup.classList.toggle('-focus-outline', focus)
    })
  }
})
