/*
 * This compiler validates all fields that have a suggestion ([form-with-suggestions-field]) with their new suggestion
 * whenever the input of the trigger changes. This compiler works in combination with the compiler for the
 * suggestion fields (see: form_with_suggestions_field.js)
 *
 * Requirements:
 *  - Trigger element must be an input field
 *  - The selector for the container must be an id so that only affected fields get their suggestions updated
 *
 * Usage:
 *   .row[id=row-1]
 *     input[name=foo][form-with-suggestions-trigger][data-input-fields-container-id=row1]
 *     [form-with-suggestions-field]
 *       // Input field with a suggestion below
 *     [form-with-suggestions-field]
 *       // Input field with a suggestion below
 */
import environment from '../util/environment'

up.compiler('[form-with-suggestions-trigger]', (element, data) => {
  const inputFieldsContainerSelector = `#${data.inputFieldsContainerId}`

  function updateSuggestionsInsideFieldsContainer() {
    up.validate(inputFieldsContainerSelector, {
      headers: {
        'validate-intent': 'update-suggestions',
      },
    })
  }

  // For inputs with dropdowns the form can´t be revalidated until the select is blurred,
  // because the dropdown might want to stay open after typing, but would be forcefully closed by a revalidation
  if (element.hasAttribute('tom-select') && !environment.isTest) {
    up.on(element, 'tom-select:blur', updateSuggestionsInsideFieldsContainer)
  } else if (element.classList.contains('string_with_dropdown')) {
    up.on(element, 'field-with-dropdown:change', updateSuggestionsInsideFieldsContainer)
  } else {
    up.on(element, 'change', updateSuggestionsInsideFieldsContainer)
  }
  up.on(element, 'form-field:suggestion-applied', updateSuggestionsInsideFieldsContainer)
})
