import requireElement from '../util/require_element'

up.compiler('[reference-impact-chooser-modal--option]', (element, { id, label }) => {
  const OPTION_RADIO_SELECTOR = '[reference-impact-chooser-modal--option-radio]'
  const radioElement = requireElement(element, OPTION_RADIO_SELECTOR)

  function init() {
    up.on(element, 'click', chooseOption)
  }

  function chooseOption(evt) {
    radioElement.checked = 'checked'
    up.emit('lci-mapping:selected', { id, label })
  }

  init()
})
