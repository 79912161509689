// Single standing euro values should be displayed with at least two decimal points
// and at most four decimal points
// E.g. 1.23456 should become 1.2346€, but 1.230000 should become 1.23€
export function formatAsEuros(value, minPrecision = 2, maxPrecision = 4) {
  const roundedValue = value
    .toFixed(maxPrecision)
    .replace(RegExp(`0{0,${maxPrecision - minPrecision}}$`, 'g'), '')
  return `${roundedValue} €`
}

// A fixed precision should be used whenever multiple values are compared, e.g. in a table or matrix
export function formatAsFixedPrecisionEuros(value, precision = 4) {
  return formatAsEuros(value, precision, precision)
}
