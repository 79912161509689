import application from '../util/application'
import environment from '../util/environment'
import Timeout from '../util/timeout'

const config = application.configure('[flash-message]', {
  timeoutDelay: 5000, // Keep in sync with the .flash-message--icon animation duration
  updateFrequency: 100,
})

up.compiler('[flash-message]', (element) => {
  const indicator = element.querySelector('[flash-message--timer-indicator]')
  const closeButton = element.querySelector('[flash-message--close]')

  function updateIndicator() {
    const percent = removeTimer ? removeTimer.percent : 0

    if (!environment.isFeatureSpec) {
      up.element.setStyle(indicator, {
        transition: `width ${config.updateFrequency}ms linear`,
        width: `${percent}%`,
      })
    }
  }

  function removeAutomatically() {
    if (environment.isFeatureSpec) {
      // We don't want to remove flashes automatically in feature tests because
      // - tests might not see flashes if they are slow,
      // - it could cause flickering behavior if flashes cover elements only sometimes.
    } else {
      updateIndicator()
      remove()
    }
  }

  function remove(evt) {
    if (evt?.type === 'keydown') {
      if (evt.code === 'Space') {
        evt.preventDefault() // prevent scrolling down on the page
      } else {
        return // ignore any other keys like tabbing or pressing shift etc.
      }
    }
    window.clearInterval(updateIndicatorInterval)
    removeTimer.clear()
    up.animate(element, 'move-to-top').then(() => { element.remove() })
  }

  const removeTimer = new Timeout(removeAutomatically, config.timeoutDelay)
  const updateIndicatorInterval = window.setInterval(updateIndicator, config.updateFrequency)

  updateIndicator()
  up.on(element, 'mouseenter', () => { removeTimer.pause() })
  up.on(element, 'mouseleave', () => { removeTimer.resume() })
  up.on(closeButton, 'click keydown', remove)

  return remove
})
