import asyncCompiler from '../util/async_compiler'
import LeafletMap from '../util/leaflet_map'
import environment from '../util/environment'
import requireElement from '../util/require_element'

asyncCompiler('.form-map', async function(element, {
  iconUrl,
  iconRetinaUrl,
  shadowUrl,
  fingerprintedJSPath,
  fingerprintedCSSPath,
}) {
  if (environment.isFeatureSpec) return

  const inputField = requireElement(element, '.form-map--input-field')
  const mapPreview = requireElement(element, '.form-map--preview')

  try {
    const leafletMap = new LeafletMap({
      iconUrl,
      iconRetinaUrl,
      shadowUrl,
    })
    await leafletMap.init(mapPreview, fingerprintedJSPath, fingerprintedCSSPath)

    // Display the location if a value for the location already exists
    await displayLocation()

    // Display the location when a new value for the location is entered
    const stopObserve = up.watch(inputField, displayLocation)

    async function displayLocation() {
      const value = extractLocation(inputField)
      if (value) {
        await leafletMap.setCoordinatesFromLocation(value)
      }
    }

    function extractLocation(input) {
      if (input.nodeName === 'SELECT' && !input.multiple) {
        return Array.from(input.selectedOptions)[0]?.value
      } else {
        return input.value
      }
    }

    return () => {
      leafletMap.destroy()
      stopObserve()
    }
  } catch (e) {
    mapPreview.innerText = 'The map is currently not available'
    console.log('Leaflet error:')
    console.log(e)
  }
})
