class PageDirtyTracker {
  constructor() {
    this.dirtyForms = new Set([])
  }

  trackDirty(form) {
    const pageWasPristine = this.isPristine
    if (!this.dirtyForms.has(form)) {
      this.dirtyForms.add(form)
    }
    if (pageWasPristine) {
      up.emit('page:dirty')
    }
  }

  trackPristine(form) {
    this.dirtyForms.delete(form)
    if (this.isPristine) {
      up.emit('page:pristine')
    }
  }

  get isPristine() {
    return this.dirtyForms.size === 0
  }

  /* Only use this in tests! */
  reset() {
    this.dirtyForms = new Set([])
  }
}

export default new PageDirtyTracker()
