const REFERENCE_IMPACTS_TO_LABEL = {
  climate_change_kg: 'global warming',
  terrestrial_acidification_kg: ['acidification', 'terrestrial acidification'],
  freshwater_eutrophication_kg: 'freshwater eutrophication',
  marine_eutrophication_kg: 'marine eutrophication',
  summer_smog_kg: 'ozone formation, terrestrial ecosystems',
  stratospheric_ozone_depletion_kg: 'stratospheric ozone depletion',
  land_use_square_metres: 'land use',
  water_use_cube_metres: 'water consumption',

  // the following impact categories are not yet exported by SimaPro
  // particulate_matter_formation_kg: '',
  // ionising_radiation_kbq: '',
}

function getReferenceImpactByLabel(label) {
  const impact = Object.entries(REFERENCE_IMPACTS_TO_LABEL).find(([_, value]) => {
    return Array.isArray(value) ? value.includes(label) : value === label
  })
  return impact ? impact[0] : null
}

export function parseImpacts(clipboardText) {
  // Split the text into lines
  const lines = clipboardText.trim().split('\n')

  // Find the line where the actual data starts
  const dataStartIndex = lines.findIndex(line => line.trim().startsWith('Impact category')) + 1

  // Parse the data lines
  const results = []
  for (let i = dataStartIndex; i < lines.length; i++) {
    const line = lines[i].trim()
    if (line === '') continue // Skip empty lines

    // Split the line by tab or multiple spaces (to handle different clipboard formats)
    const columns = line.split(/\t+|\s{2,}/).map(column => column.trim())

    // Ensure we have at least 3 columns (we are interested in the 3rd column for 'Total')
    if (columns.length < 3) continue

    const key = getReferenceImpactByLabel(columns[0].toLowerCase())
    if (!key) continue // Skip unknown impact categories

    const value = columns[2].replace(/,/g, '.') // Replace comma and parse as float
    if (isNaN(value)) continue // Skip invalid values

    results.push({
      key,
      value,
    })
  }

  return results
}
