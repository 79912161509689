up.compiler('.action-table--actions', (element) => {
  up.on(element, 'click', (evt) => {
    if (!isLinkOrHasLinkAncestor(evt.target)) {
      // Dont trigger the `up-expand` of the table rows within the actions section.
      // Stop the propagation only for non link elements, to allow for e.g. `up-method` to work.
      evt.stopPropagation()
    }
  })

  function isLinkOrHasLinkAncestor(element) {
    while (element) {
      if (element.tagName === 'A') {
        return true
      }
      element = element.parentElement
    }
    return false
  }
})
