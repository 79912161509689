/*
  (Re)Validate a form within the given element when the "playground:substitution-requested"
  event is being emitted.
  The event carries a reference impact ID which should next be substituted, so we first
  assign it to a special hidden form field.
 */

up.compiler('[substitution-form]', element => {
  let form = element.querySelector('form') // Can be missing if the lCA is not ready for analysis yet

  function revalidateWithSubstitution({ referenceImpactId }) {
    form.querySelector('#next_reference_impact_id_to_substitute').value = referenceImpactId
    revalidateForm('append-new-reference-impact')
  }

  function onChange(_value, selector) {
    if (!selector.includes('_destroy')) {
      revalidateForm()
    }
  }

  function revalidateForm(validateIntent = null) {
    window.CapybaraLockstep?.startWork('revalidating-substitution-form')
    up.validate('[substitution-form--content]', { headers: { 'validate-intent': validateIntent } })
      .catch((e) => console.warn(e)) // 422 is fine in this case!
      .finally(() => {
        // the up.validate() request replaces the (dirty) form with a new copy
        // that has lost its dirtyness state.
        form = element.querySelector('form')
        up.emit(form, 'form:dirty')
        window.CapybaraLockstep?.stopWork('revalidating-substitution-form')
      })
  }

  return [
    up.on('playground:substitution-requested', revalidateWithSubstitution),
    up.watch(element, onChange),
  ]
})
