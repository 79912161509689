import loadStyle from './load_style'

export default class LeafletMap {
  constructor(options) {
    this.options = options
    this.marker = undefined
    this.longitude = undefined
    this.latitude = undefined
  }

  async init(mapElement, fingerprintedJSPath, fingerprintedCSSPath) {
    if (fingerprintedJSPath) {
      await import(fingerprintedJSPath) // Loads leaflet to window.L
    }
    if (fingerprintedCSSPath) {
      await loadStyle(fingerprintedCSSPath)
    }
    this.map = L.map(mapElement)

    // add the OpenStreetMap tiles
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    }).addTo(this.map)

    // show the scale bar on the lower left corner
    L.control.scale({ imperial: true, metric: true }).addTo(this.map)

    L.Icon.Default.mergeOptions(this.options)

    this.setCoordinates({
      longitude: 0,
      latitude: 0,
    })
  }

  async fetchCoordinates(value) {
    const url = new URL('https://nominatim.openstreetmap.org/')
    url.searchParams.set('q', value.toLowerCase())
    url.searchParams.set('format', 'json')
    url.searchParams.set('limit', '1')

    const response = await fetch(url)
    const jsonData = await response.json()
    if (jsonData.length === 0) {
      return null
    }

    const longitude = Number.parseFloat(jsonData[0].lon)
    const latitude = Number.parseFloat(jsonData[0].lat)

    return { longitude, latitude }
  }

  setCoordinates(coordinates) {
    this.map.setView({ lon: coordinates.longitude, lat: coordinates.latitude }, 12)

    this.longitude = coordinates.longitude
    this.latitude = coordinates.latitude

    // remove marker of previous input
    this.marker?.remove()

    // show a marker on the map
    this.marker = L.marker({ lon: coordinates.longitude, lat: coordinates.latitude })
    this.marker.addTo(this.map)
  }

  getCoordinates() {
    return {
      longitude: this.longitude,
      latitude: this.latitude,
    }
  }

  async setCoordinatesFromLocation(inputValue) {
    const coordinates = await this.fetchCoordinates(inputValue)
    if (coordinates) {
      this.setCoordinates(coordinates)
    }
  }

  destroy() {
    this.map.remove()
  }
}
