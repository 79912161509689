// Apply this compiler to any clickable element to show a confirmation dialog before actually clicking the element.
// Required attributes: data-confirm-icon, data-confirm-title
// Optional attributes: data-confirm-text
//
// Example usage:
//  <button>
//     confirm-click="true"
//     data-confirm-icon="<span class=&quot;icon -confirm-deletion -x60 &quot; aria-label=&quot;Icon for “Confirm deletion”&quot;></span>"
//     data-confirm-title="Do you really want to delete “ACME Brand“?"
//     data-confirm-text="This action cannot be undone."
//  </button>

up.compiler('[confirm-click]', (element, { confirmIcon, confirmTitle, confirmText }) => {

  up.on(element, 'up:click', handleClick)

  function handleClick(evt) {
    if (evt.clickConfirmed) {
      return
    }

    evt.preventDefault()
    openConfirmationDialog(evt)
  }

  function openConfirmationDialog(evt) {
    up.layer.open({
      content: confirmationDialogTemplate(confirmIcon, confirmTitle, confirmText),
      layer: 'new',
      mode: 'modal',
      history: false,
      onAccepted,
    })
  }

  function onAccepted() {
    up.emit(element, 'up:click', { clickConfirmed: true })
  }

  function confirmationDialogTemplate(icon, title, text) {
    return (`
    <div class="modal-hint">
      <div class="modal-hint--icon">
        ${icon}
      </div>
      <h3 class="modal-hint--title">
        ${title}
      </h3>
      
      ${text}
      
      <div class="action-bar -with-action-count-2 modal-hint--actions">
        <button class="btn btn-outline-secondary" type="button" up-dismiss>
          ${SelectiveI18n.t('words.cancel')}
        </button>
        <button class="btn btn btn-secondary" type="button" up-accept>
          ${SelectiveI18n.t('words.confirm')}
        </button>
      </div>
    </div>
  `)
  }
})
