import asyncCompiler from '../util/async_compiler'
import loadStyle from '../util/load_style'
import MobileDetector from '../util/is_mobile'

const ISO_DATE_PATTERN = /^\d{4}-\d\d?-\d\d$/

asyncCompiler('[date-picker]', async (element, { previousMonthIcon, nextMonthIcon, fingerprintedJSPath, fingerprintedCSSPath }) => {

  // keep native date picker on mobile devices
  if (MobileDetector.isMobile) return

  if (fingerprintedJSPath) {
    await import(fingerprintedJSPath) // Loads flatpickr to window.flatpickr
  }
  if (fingerprintedCSSPath) {
    await loadStyle(fingerprintedCSSPath)
  }

  const picker = flatpickr(element, {
    // locale: english,
    allowInput: true,
    firstDayOfWeek: 1,
    altInput: false,
    // altFormat: 'Y-m-d',
    dateFormat: 'Y-m-d',
    ariaDateFormat: 'F J, Y',
    // ariaDateFormat: 'Y-m-d',
    prevArrow: previousMonthIcon,
    nextArrow: nextMonthIcon,
  })

  element.setAttribute('date-picker--user-input', true)

  // When users type into the input, we want to immediately reflect their input in the picker.
  // For that, we need to trigger a `blur` event. Because that would also update the user input,
  // we only do it when a well-formatted value is given.
  element.addEventListener('input', function() {
    if (!element.value || element.value.match(ISO_DATE_PATTERN)) {
      up.emit(element, 'blur')
    }
  })

  // When an invalid value was entered, the field ist blurred, we want to clear the user input.
  // Otherwise flatpickr would try to parse it and maybe pick a date that the user did not actually enter.
  // Discarding any input in that case felt like the saner approach.
  element.addEventListener('change', function() {
    if (element.value && !element.value.match(ISO_DATE_PATTERN)) {
      element.value = ''
    }
  })

  up.on(element, 'date-picker:close', picker.close)

  return () => {
    picker.destroy()
  }

})
