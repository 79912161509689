import { formatAsEuros } from './format_as_euros'

export default class DetailedCostsChart {
  constructor(detailedLCACosts) {
    this.detailedLCACosts = detailedLCACosts
  }

  get options() {
    const options = {
      animation: false,
      tooltip: {
        formatter: this.tooltipFormatter,
      },
      series: [
        {
          type: 'treemap',
          nodeClick: 'zoomToNode',
          zoomToNodeRatio: 0.4 * 0.4, // The treemap will be auto zoomed to a appropriate ratio when a node is clicked. This configuration item indicates the ratio.
          leafDepth: 2, // start with two layers (stage + category or biggest contributors). Enables "drill down"
          visibleMin: 100 * 100, // hide nodes smaller than 100px² (size is tied to the environmental impact)
          breadcrumb: {
            top: 40,
          },
          // roam: 'move', // can be enabled to prevent freely dragging the chart. Seems better at first, but makes it impossible to substitute processes with a low impact
          upperLabel: {
            show: true,
            height: 30,
            shadowBlur: 0,
            color: 'white',
            formatter: this.upperLabelFormatter,
          },
          labelLayout: this.labelLayouter,
          label: {
            formatter: this.labelFormatter,
            rich: {
              boxTitle: {
                fontSize: 16,
              },
              euros: {
                fontSize: 18,
                fontWeight: 'bold',
                padding: 10,
              },
            },
          },
          name: 'Total costs',
          data: this.data,
          itemStyle: {
            borderColor: 'transparent',
          },
          levels: [
            // treemap config
            {
              // configurations of the top level (LCA)
              itemStyle: {
                borderWidth: 0,
                gapWidth: 10,
              },
            },
            {
              // configurations of LCA stages
              visibleMin: 0, // Show stage names even if they % fraction is tiny
              upperLabel: {
                fontWeight: 'bold',
                fontSize: '20',
              },
              itemStyle: {
                borderWidth: 1,
                gapWidth: 5,
              },
            },
            {
              // configurations of LCA stages categories / rows
              upperLabel: {
                fontSize: '16',
              },
              itemStyle: {
                borderWidth: 1,
                gapWidth: 5,
              },
            },
            {
              // configurations of LCA rows
              itemStyle: {
                borderWidth: 1,
                gapWidth: 1,
              },
            },
          ],
        },
      ],
    }
    console.debug(options)
    return options
  }

  tooltipFormatter(params) {
    return `<div class="tooltip-title">${params.name}</div>
            Evironmental costs: ${formatAsEuros(params.value)}`
  }

  upperLabelFormatter(params) {
    if (params.name && params.data.children && params.data.children.length > 0) {
      return `${params.name} (${formatAsEuros(params.value)})`
    } else {
      // leafs already show their cost in the normal label
      // the outermost layer has no label
      return params.name
    }
  }

  labelLayouter(params) {
    const layout = {
      align: 'center',
    }
    if (params.rect.width * params.rect.height > 150 * 150) {
      layout.verticalAlign = 'top' // weirdly enough, that corresponds to "middle"..
    }
    return layout
  }

  labelFormatter(params) {
    return [
      `{boxTitle|${params.name}}`,
      `{euros|${formatAsEuros(params.value)}}`,
    ].join('\n')
  }

  get data() {
    // the LCA would be depth 0, its fragments (the stages) are depth 1
    return this.costSubtree(this.detailedLCACosts.fragments, 1)
  }

  costSubtree(fragments, depth) {
    return fragments.map(costFragment => {
      const totalCosts = parseFloat(costFragment.total_cost_euros)
      if (totalCosts <= 0) {
        return null // Empty cost fragments should not be part of the treemap
      }
      const costHash = {
        name: costFragment.name,
        value: totalCosts, // this also controls the area size of the box
        depth,
        itemStyle: {},
        upperLabel: {},
      }
      if (costFragment.fragments.length > 0) {
        costHash.children = this.costSubtree(costFragment.fragments, depth + 1)
        costHash.substituted = !!costHash.children.every(child => child.substituted)
        costHash.invertedDepth = (costHash.children[0]?.invertedDepth || 0) + 1
      } else {
        costHash.invertedDepth = 0
        costHash.referenceImpactId = costFragment.reference_impact_id
        costHash.environmentalCostSetId = costFragment.environmental_cost_set_id
        costHash.lcaID = costFragment.lca_id
        costHash.amount = costFragment.amount
        costHash.substituted = costFragment.substituted
        costHash.itemStyle = {
          borderWidth: 1,
          gapWidth: 1,
        }
      }

      if (costHash.invertedDepth < 2) {
        costHash.visibleMin = 0 // Always show leafs and rows if their parents are visible
      }

      if (depth === 2 && costHash.invertedDepth === 2) {
        // Style categories with dotted headlines
        // They are at "depth 2" (LCA > Stages > Categories)
        // and at "inverted depth 2" (LCI < Row < Category)
        costHash.name = `Category: ${costHash.name}`
      }

      if (costHash.substituted && costHash.invertedDepth < 3) {
        // Mark the last layers of a fully substituted subtree with a white border
        costHash.itemStyle.borderColor = 'white'
        costHash.itemStyle.gapWidth = 1
        costHash.itemStyle.borderWidth = 1
        // The border breaks the upperLabel which is now also white. We have to apply these hacks to restore its original appearance..
        costHash.upperLabel.backgroundColor = '#1C1C21' // background-level-2
        costHash.upperLabel.borderWidth = 2
        costHash.upperLabel.borderColor = '#1C1C21' // background-level-2
      }
      return costHash
    }).filter(data => !!data) // filter out null values
  }
}
