/*
   This compiler temporarily adds a `body.-loading` class to the DOM
   until _after_ it was rendered.
   It can be used e.g. to prevent transitions that are only meant for
   user interaction.
 */
up.compiler('body', (element) => {

  const LOADING_MODIFIER = '-loading'

  const setLoading = () => {
    element.classList.add(LOADING_MODIFIER)
  }

  const resetLoading = () => {
    element.classList.remove(LOADING_MODIFIER)
  }

  return [
    up.on('up:fragment:loaded up:location:restore up:network:late', setLoading),
    up.on('up:fragment:inserted up:framework:booted up:network:recover', resetLoading),
  ]

})
