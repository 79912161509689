import autosize from 'autosize'

up.compiler('textarea', (element) => {
  autosize(element)

  // Textareas are sometimes not initialized to the correct height, probably due to (re)paint issues.
  // This should avoid the problem most of the time. Sorry.
  const updateSizeTimeout = setTimeout(() => { autosize.update(element) }, 50)

  return () => {
    clearTimeout(updateSizeTimeout)
    autosize.destroy(element)
  }
})
