up.compiler('.nav-tabs', (element) => {
  const ITEM_SELECTOR = '.nav-link'
  const ACTIVE_ITEM_SELECTOR = '.nav-link.active'
  const DIRTY_MODIFIER = '-dirty'

  function handlePageDirty() {
    element.querySelector(ACTIVE_ITEM_SELECTOR).classList.add(DIRTY_MODIFIER)
  }

  function handlePagePristine() {
    element.querySelectorAll(ITEM_SELECTOR).forEach((item) => {
      item.classList.remove(DIRTY_MODIFIER)
    })
  }

  return [
    up.on('page:dirty', handlePageDirty),
    up.on('page:pristine', handlePagePristine),
  ]
})
