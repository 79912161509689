import environment from '../util/environment.js'
import asyncCompiler from '../util/async_compiler'
import tooltipDestructor from '../util/tooltip_destructor'

const tooltipPromise = import('bootstrap/js/dist/tooltip')

asyncCompiler('input:not([data-bs-toggle]), [tooltip-on-overflow]', async (element) => {
  const Tooltip = (await tooltipPromise).default
  let tooltip

  up.on(element, 'mouseover', () => {
    if (element.scrollWidth <= element.clientWidth) {
      return
    }

    if (element.tagName === 'INPUT' && !element.disabled) {
      return
    }

    tooltip = new Tooltip(element, {
      animation: !environment.isTest,
      trigger: '',
      title: element.value || element.innerText,
    })

    tooltip.show()
  })

  up.on(element, 'mouseleave', () => {
    tooltip?.hide()
  })

  return () => {
    if (tooltip) {
      tooltipDestructor.bind(tooltip).call()
    }
  }
})
