import environment from '../util/environment'
import asyncCompiler from '../util/async_compiler'
import tooltipDestructor from '../util/tooltip_destructor'
const tooltipPromise = import('bootstrap/js/dist/tooltip')

asyncCompiler('[row-value-mismatch-warning]', async function(element, { mismatchTooltip }) {
  const Tooltip = (await tooltipPromise).default
  const originalValue = element.getAttribute('row-value-mismatch-warning')

  const warningIcon = document.createElement('span')
  warningIcon.classList.add('icon', '-lca-different-configuration-warning',
    '-x20', 'warning')
  warningIcon.setAttribute('title', mismatchTooltip)

  const label = element.querySelector('.label-wrap')

  const tooltip = new Tooltip(warningIcon, {
    title: mismatchTooltip,
    animation: !environment.isTest,
  })

  if (environment.isTest) {
    element.tooltip = tooltip
  }

  function toggleWarning() {
    if (originalValue !== element.querySelector('input:checked').value) {
      tooltip.enable()
      label.appendChild(warningIcon)
    } else {
      tooltip.disable()
      warningIcon.remove()
    }
  }

  toggleWarning()

  return [
    tooltipDestructor.bind(tooltip),
    up.on(element, 'input', function() { toggleWarning() }),
  ]
})
