/**
 * This compiler opens a given HTML string in a non-dismissable
 * modal. The modal thus _appears_ to be the origin layer.
 * Please note that you cannot easily target elements of the
 * HTML string with forms as it's not part of the server response.
 * Unpoly is therefore configured to not follow links and submit forms
 * within the instant modal.
 **/
up.compiler('[instant-modal]', (element, data) => {
  up.layer.open({
    class: '-for-instant-modal',
    content: data.html,
    dismissable: false,
    history: false,
    size: data.size || 'medium',
  })
})
