export default async function tooltipDestructor() {
  return new Promise((resolve) => {
    // The disposal of tooltips is not exactly _instant_, but very fast.
    // Waiting for a microtask (which we do by resolving this promise)
    // suffices to catch any TypeErrors that might occur when the element
    // is being detached from the DOM before the disposal was completed.
    this.dispose()
    resolve()
  })
}
