const stylesLoaded = {}

export default function loadStyle(url) {

  function createStyleTag(url) {
    const styleTag = document.createElement('link')
    styleTag.setAttribute('rel', 'stylesheet')
    styleTag.setAttribute('href', url)
    return styleTag
  }

  const cachedPromise = stylesLoaded[url]
  if (cachedPromise) {
    return cachedPromise
  } else {
    const promise = new Promise((resolve, reject) => {
      const styleTag = createStyleTag(url)
      styleTag.addEventListener('load', resolve)
      styleTag.addEventListener('error', reject)
      document.head.appendChild(styleTag)
    })
    stylesLoaded[url] = promise
    return promise
  }
}
