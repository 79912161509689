import { parseImpacts } from '../util/simapro_parser'

up.compiler('[paste-impacts]', (element) => {

  async function pasteImpacts() {
    let clipboardText = ''
    try {
      clipboardText = await navigator.clipboard.readText()
    } catch (error) {
      console.error(error)
    }

    const impacts = parseImpacts(clipboardText)
    impacts.forEach(({ key, value }) => {
      const input = element.querySelector(`[name="reference_impact[${key}]"]`)
      if (input) {
        input.value = value
        up.layer.emit(input, 'change')
      }
    })
  }

  return [
    up.on(element, 'click', '.btn-paste-impacts', pasteImpacts),
  ]
})
