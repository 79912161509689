import asyncCompiler from '../util/async_compiler'

asyncCompiler('[intro-animation]', async (element, {
  fingerprintedJSPath,
  src,
}) => {
  await import(fingerprintedJSPath) // Loads window.Lottie

  document.body.classList.add('-intro-animation-running')
  const animation = Lottie.loadAnimation({
    container: element,
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: src,
  })

  const reload = () => {
    /* The login form will be displayed on every subsequent page load */
    document.location.reload()
  }
  const fadeOut = () => {
    /* The logo within the SVG is padding with quite a lot of whitespace which causes our transition
       to obviously miss the upper left edge of the screen. We have to account for this difference
       by calculating it via Javascript */
    element.classList.add('-finished')
    const svgImages = element.querySelectorAll('image')
    const logoPosition = svgImages[svgImages.length - 1].getBoundingClientRect()
    element.style.transform = `scale(0.10) translate(-${logoPosition.left}px, -${logoPosition.top}px)`
    element.addEventListener('transitionend', reload, { once: true })
  }

  animation.addEventListener('complete', () => {
    fadeOut()
    document.body.classList.remove('-intro-animation-running')
  })

  return () => {
    animation.removeEventListener('complete', fadeOut)
    element.removeEventListener('transitionend', reload)
    animation.destroy()
    document.body.classList.remove('-intro-animation-running')
  }
})
