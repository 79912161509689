import merge from 'lodash/merge'
import asyncCompiler from '../util/async_compiler'

asyncCompiler('[stacked-bar-chart]', async (element, { options, fingerprintedJSPath }) => {
  if (fingerprintedJSPath) {
    await import(fingerprintedJSPath) // Loads window.echarts
  }

  const fontFamily = getComputedStyle(element).getPropertyValue('--headings-font-family')
  const color = getComputedStyle(element).getPropertyValue('--ui-primary')

  const defaultOptions = {
    animation: false,
    grid: {
      top: 90,
      bottom: 40,
      left: 0,
      right: 0,
      containLabel: true,
    },
    legend: {
      top: 20,
      textStyle: {
        color,
        fontFamily,
      },
      type: 'scroll',
      left: 'left',
    },
    tooltip: {
      axisPointer: {
        type: 'shadow',
      },
      trigger: 'item',
      borderWidth: 0,
      padding: 0,
      formatter: tooltipFormatter,
    },
    xAxis: [
      {
        type: 'category',
        axisLabel: {
          color,
          fontFamily,
          interval: '0',
          width: 120,
          overflow: 'break',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          color,
          fontFamily,
          interval: '0',
          formatter: '{value}%',
        },
        max: 100,
      },
    ],
  }

  const chart = window.echarts.init(element)
  chart.setOption(merge({}, defaultOptions, options))

  return () => chart.dispose()

  function tooltipFormatter(params) {
    const value = params.data[params.seriesName]
    const formattedValue = parseFloat(value).toFixed(2) + '%'
    let tooltipContent = `
      <div class="echarts-tooltip">
        <span class="echarts-tooltip--headline">${params.seriesName}</span><br>
        
        <div class="echarts-tooltip--value-container">
          ${params.marker}${params.name}
          <span class="echarts-tooltip--value">${formattedValue}</span>
        </div>
    `

    if (params.data.rawValues && params.data.rawValues[params.seriesName]) {
      const rawValue = params.data.rawValues[params.seriesName]
      const formattedRawValue = `${parseFloat(rawValue).toExponential(5)} ${params.data.unit}`

      tooltipContent += `
        <div class="echarts-tooltip--value-container">
          <span class="echarts-tooltip--value">${formattedRawValue}</span>
        </div>
      `
    }

    tooltipContent += '</div>'

    return tooltipContent
  }
})
