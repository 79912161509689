// Prevent horizontal scrolling of text within inputs, if the text is ellipsed,
// as this lead to the text scrolling out of the input field and leaving blank space behind.
// When the input is focused the text is automatically unellipsed, we need to allow scrolling in that case.
// If the input is disabled and ellipsed we never allow scrolling.

up.compiler('input', (element) => {

  function preventScrollingForEllipsedInputs(evt) {
    const scrollable = evt.target.scrollWidth > evt.target.clientWidth
    const canBeTruncated = getComputedStyle(evt.target).getPropertyValue('text-overflow') === 'ellipsis'
    const disabled = evt.target.getAttribute('disabled')
    const focused = evt.target === document.activeElement
    const disableScrolling = scrollable && canBeTruncated && (disabled || !focused)

    if (disableScrolling) {
      evt.target.scrollLeft = 0
    }
  }

  up.on(element, 'scroll', preventScrollingForEllipsedInputs)
})
