import environment from '../util/environment.js'
import asyncCompiler from '../util/async_compiler'
import tooltipDestructor from '../util/tooltip_destructor'

const tooltipPromise = import('bootstrap/js/dist/tooltip')

asyncCompiler('[data-bs-toggle="tooltip"]', async (element) => {
  const Tooltip = (await tooltipPromise).default

  const tooltip = new Tooltip(element, {
    animation: !environment.isTest,
  })
  element.tooltip = tooltip // expose the tooltip instance to the DOM

  return tooltipDestructor.bind(tooltip)
})
