// This compiler allows a form fragment to be edited in an overlay:
//
//     <form>
//       <!-- These fields are shown in the main form -->
//       <input ...>
//       <input ...>
//
//       <div class="subform-in-overlay">
//         <div class="subform-in-overlay--fields">
//           <!-- These fields are hidden in the main form. -->
//           <!-- After clicking the opener below, these fields are shown in an overlay -->
//           <input ...>
//           <input ...>
//
//           <!-- When the overlay is accepted, the fields are re-attached to the main form -->
//           <button type="button" up-accept>
//         </div>
//         <div class="subform-in-overlay--opener">
//           Show fields in overlay
//         </div>
//
//       </div>
//     </form>
//
up.compiler('.subform-in-overlay', (container, { revalidate, modalSize = 'medium' }) => {
  const opener = container.querySelector('.subform-in-overlay--opener')
  let fields = container.querySelector('.subform-in-overlay--fields')
  const form = fields.closest('form')

  async function open() {
    up.layer.open({
      fragment: fields,
      // We're hooking into { onAccept } instead of { onAccepted } so we can reclaim
      // the fields element before destructors are called.
      onAccept: reclaimFields,
      onDismissed: reclaimFields,
      dismissable: true,
      history: false,
      size: modalSize,
    })
  }

  function reclaimFields() {
    container.append(fields)
    up.emit(form, 'form:update-dirty')
    const hasWarning = fields.querySelectorAll('.warning').length > 0
    container.classList.toggle('-has-warning', hasWarning)
  }

  async function revalidateFields() {
    await up.validate(fields)
    // Our old "fields" reference becomes stale after revalidating
    fields = container.querySelector('.subform-in-overlay--fields')
  }

  opener.addEventListener('click', async function() {
    if (revalidate === 'true') {
      await revalidateFields()
    }
    open()
  })

  // If the fields show a validation error, immediately open the overlay.
  if (fields.querySelector('.invalid-feedback')) {
    open()
  }
})
