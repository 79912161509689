import requireElement from '../util/require_element'

up.compiler('[reference-impact-chooser-modal]', (element) => {
  const confirmButton = requireElement(element, '[reference-impact-chooser-modal--confirm-cta]')
  let selectedId
  let selectedLabel

  function init() {
    return [
      up.on(confirmButton, 'click', closeModal),
      up.on('lci-mapping:selected', mappingSelected),
    ]
  }

  function closeModal() {
    up.layer.accept(
      {
        id: selectedId,
        label: selectedLabel,
      },
      {
        history: false,
      },
    )
  }

  function mappingSelected(evt) {
    selectedId = evt.id
    selectedLabel = evt.label
    confirmButton.disabled = false
  }

  return init()
})
