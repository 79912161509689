import requireElement from '../util/require_element'
import CSVBuilder from '../util/csv_builder'

/*
 * Export structured data client as a client-side generated CSV file
 * Usage:
 * - Either via the download_diagram_data_button() helper
 * - or with HTML that is structured as followed:
 *
 * [downloadable-data][data-rawFilename="My file"][up-data={ headers: ARRAY, rows: ARRAY of ARRAYs}]
 *   button[downloadable-data--cta]
 */

up.compiler('[downloadable-data]', async (element, {
  rawFilename,
  headers,
  rows,
}) => {
  const button = requireElement(element, '[downloadable-data--cta]')
  const MIME_TYPE = 'text/csv;encoding:utf-8'

  function init() {
    if (rows.length === 0) {
      button.disabled = true
    } else {
      up.on(button, 'click', downloadData)
    }
  }

  function downloadData() {
    const fileContent = new CSVBuilder(headers, rows).toString()
    const filename = sanitizeFilename(rawFilename)
    sendFile(filename, fileContent)
  }

  function sanitizeFilename(rawFileName) {
    return (rawFileName.toLowerCase().trim().replace(/[^A-z0-9]/g, '_') + '.csv').replace(/__+/g, '_')
  }

  // Sending a named CSV file requires us to build a temporary a[download] element
  function sendFile(fileName, fileContent) {
    if (element.mockCSVDownload) {
      window.lastDownload = {
        fileName,
        fileContent,
      }
      return // Don't actually trigger the file download in tests
    }
    const csvBlob = new Blob([fileContent], {
      type: MIME_TYPE,
    })
    const temporaryLink = up.element.affix(element, 'a.visually-hidden', {
      download: fileName,
      href: URL.createObjectURL(csvBlob),
    })
    temporaryLink.click()
    temporaryLink.remove()
  }

  init()
})
