// A fieldset[disabled] stops propagation of mouse events in Firefox.
// Event-bound code like an `[up-modal]` compiler would not work.
//
// There is no way to change this in Firefox. We use a `no-edit` attribute
// instead, and disable all fields manually.
//
//
// All nested fields of an element (e.g. form) with a `no-edit` attribute will be disabled
// Example result:
//   %form[no-edit]
//     %input[disabled]
//     %textarea[disabled]
//
// Select all nested fields within an element (e.g. form) with a `no-edit` attribute
// Example result (simplified): '[no-edit] select, [no-edit] input, [no-edit] textarea'
const noEditInputs = up.form.config.fieldSelectors.map(field => `[no-edit] ${field}`).join(', ')

up.compiler(noEditInputs, function(element) {
  element.disabled = true
})
