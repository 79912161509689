// This compiler allows any content to be rendered in an overlay:
//
//     <div class="content-in-overlay">
//       <div class="content-in-overlay--body">
//         <!-- The content is hidden in the root layer -->
//         <!-- After clicking the opener below, the content is shown in an overlay -->
//         <input ...>
//         <input ...>
//
//         <!-- When the overlay is dismissed, the content gets re-attached to the container -->
//         <button type="button" up-dismiss>
//       </div>
//       <div class="content-in-overlay--opener">
//         Show content in overlay
//       </div>
//     </div>
//
up.compiler('.content-in-overlay', (container, data) => {
  const opener = container.querySelector('.content-in-overlay--opener')
  const body = container.querySelector('.content-in-overlay--body')

  const defaultOptions = {
    fragment: body,
    onDismiss: reclaimBody,
    dismissable: true,
    history: false,
  }

  function open() {
    up.layer.open(Object.assign(defaultOptions, data))
  }

  function reclaimBody() {
    container.append(body)
  }

  opener.addEventListener('click', open)
})
