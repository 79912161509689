export class FrontendApi {

  static async optionsForSelect(route, query) {
    const apiUrl = this._apiUrl(route)
    apiUrl.searchParams.append('query', query)
    apiUrl.searchParams.append('options_for_select', 'true')
    return await this._jsonResponse(apiUrl)
  }

  static _apiUrl(route) {
    if (route[0] === '/') {
      route = route.slice(1) // remove leading `/` if present
    }

    return new URL(`/${route}.json`, location.href)
  }

  static async _jsonResponse(url) {
    const response = await fetch(url)
    return await response.json()
  }

}
