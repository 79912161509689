// Registers an `up.compiler()` function that returns a promise for its destructor function.
export default function asyncCompiler(...compilerArgs) {
  const compilerFn = compilerArgs.pop()

  up.compiler(...compilerArgs, (...passArgs) => {
    // Call the compiler function passed by the user.
    const returnValue = compilerFn(...passArgs)

    // Return a new destructor function that awaits the asynchronous
    // return value and uses the fulfillment value as a destructor.
    return async () => {
      // The async function could return different types of values:
      //
      // (1) A destructor function
      // (2) An array of destructor functions
      // (3) A value that is not a function (which we discard)
      let destructorCandidates = await returnValue
      destructorCandidates = up.util.wrapList(destructorCandidates)

      for (const destructorCandidate of destructorCandidates) {
        if (up.util.isFunction(destructorCandidate)) {
          destructorCandidate()
        }
      }
    }
  })
}
