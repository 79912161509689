/*
  Render a pie chart which displays the distribution of primary / secondary / excluded
  life cycle stages.
  When hovered, the chart will display the exact stage names of the corresponding category.
 */
import asyncCompiler from '../util/async_compiler'

asyncCompiler('[lca-configuration-chart]', async (element, {
  fingerprintedJSPath,
  primaryStages,
  secondaryStages,
  excludedStages,
}) => {
  await Promise.all([
    import(fingerprintedJSPath), // Loads window.echarts
  ])
  let chart

  function init() {
    chart = window.echarts.init(element)
    element.chart = chart // expose chart instance to the DOM (and tests)
    chart.setOption({
      animation: false,
      tooltip: {
        trigger: 'item',
        position: 'inside',
        borderWidth: 0,
        padding: 0,
      },
      series: [
        {
          type: 'pie',
          radius: '100%',
          left: 'left',
          x: 'left',
          label: {
            show: false,
          },
          data: computeData(),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    })
  }

  function computeData() {
    return [
      {
        value: primaryStages.length,
        toolTipTitle: 'Primary Stages',
        stages: primaryStages,
        ...itemStyleOptions('primary'),
        ...toolTipOptions(),
      },
      {
        value: secondaryStages.length,
        toolTipTitle: 'Secondary Stages',
        stages: secondaryStages,
        ...itemStyleOptions('secondary'),
        ...toolTipOptions(),
      },
      {
        value: excludedStages.length,
        toolTipTitle: 'Excluded Stages',
        stages: excludedStages,
        ...itemStyleOptions('excluded'),
        ...toolTipOptions(),
      },
    ]
  }

  function itemStyleOptions(category) {
    return {
      itemStyle: {
        color: getComputedStyle(document.body).getPropertyValue(`--stage-configuration-${category}`),
      },
    }
  }

  function toolTipOptions() {
    return {
      tooltip: {
        formatter: (params, _ticket, _callback) => {
          return `
            <div class="echarts-tooltip">
                <span class="echarts-tooltip--headline">${params.data.stages.length} ${params.data.toolTipTitle}:</span><br>
                ${params.data.stages.join('<br>')}
            </div>
          `
        },
      },
    }
  }

  init()

  return () => chart?.dispose()
})
